import styled from 'styled-components'
import {
    Card as MuiCard,
    CardContent as MuiCardContent,
    Checkbox as MuiCheckbox,
    Grid as MuiGrid,
    Typography as MuiTypography,
    Chip as MuiChip,
    CardHeader as MuiCardHeader,
    InputLabel as MuiInputLabel,
    Select as MuiSelect,
} from '@material-ui/core'
import { spacing as MuiSpacing } from '@material-ui/system'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MuiSecondaryButton from '../../SecondaryButton/SecondaryButton'

export const Typography = styled(styled(MuiTypography)(MuiSpacing))`
&[class*="MuiTypography-colorError"] {
  color: ${(props): number => props.theme.palette.error900.main};
}
`

export const ValueTypography = styled(styled(MuiTypography)(MuiSpacing))`
  font-size: 12px;
  font-weight: 500;
`

export const Select = styled(styled(MuiSelect)(MuiSpacing))<{size?: 'small' | 'medium'}>`
  width: 100%;
  & [class^='MuiSelect-root'] {
    height: 16px;
    background: white;
  }
  & [class*="MuiSelect-select"]:focus{
        background-color: white;
  }
  & [class*="MuiInputBase-input"] {
    background: white;
  }
`

export const RelativeContainer = styled(styled.div(MuiSpacing))`
    position: relative;
`

export const InputLabel = styled(styled(MuiInputLabel)(MuiSpacing))<{$backgroundColor?: string}>`
    position: absolute;
    top: -2px;
    left: -8px;
    background: ${(props): string => props.$backgroundColor ?? 'white'};
    white-space: nowrap;
    z-index: 1;
`

export const Icon = styled(styled(FontAwesomeIcon)(MuiSpacing))``
export const SecondaryButton = styled(styled(MuiSecondaryButton)(MuiSpacing))`
  border: none;
  padding: 0;
`