import React from "react";
import { ServiceTypography, Switch } from './PureToggleButton.styled';
import { FieldConfig } from "../FormSimple";
import { Control, Controller, FieldValues } from "react-hook-form";
import { FormControlLabel } from "@material-ui/core";

export interface PureCheckboxFormProps {
    name?: string
    control: Control<FieldValues>
    setValue: (name: string, value: any, options?: any) => void
    getValues: (name?: string, options?: any) => void
    trigger: (name: string) => Promise<boolean>
    debouncedHandleOnChange: () => void
    field: FieldConfig
    errors?: any
    error?: any
    helperText?: any
    onChange?: (value?: any) => void
}

const PureToggleButton: React.FunctionComponent<PureCheckboxFormProps> = ({
    name,
    field,
    control,
    errors,
    error,
    helperText,
    setValue,
    getValues,
    trigger,
    debouncedHandleOnChange,
    onChange,
}) => {
    return <Controller
        name={name ? name : field.name}
        control={control}
        defaultValue={field.defaultValue || ''}
        render={({ field:controlField }) => {
            return (
                <>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={controlField.value} 
                                onChange={(e, checked) => {
                                    if (controlField.onChange) {
                                        controlField.onChange(checked)
                                        setValue(controlField.name, checked)
                                        debouncedHandleOnChange?.()
                                    }
                                    trigger(controlField.name).then(()=> {
                                        if (controlField.onChange) {
                                            controlField.onChange(checked)
                                            setValue(controlField.name, checked)
                                            debouncedHandleOnChange?.()
                                        }
                                    })
                                }} 
                                name={field.name}
                                size='small'
                                $color={field.toggleButton?.color}
                            />}
                            label={<ServiceTypography $fontWeight={400} ml={-2}>{field.labelName}</ServiceTypography>}
                    />
                </>
            )
        }}
        />
}

export default PureToggleButton;