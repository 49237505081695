// biome-ignore lint/style/useImportType: <explanation>
import React, { ChangeEvent, HTMLAttributes, ReactChildren, ReactElement, memo, useEffect, useState } from 'react'
import { useForm, Controller, useFormState } from 'react-hook-form'
import { Grid, Slider, Typography, type ButtonProps, type GridProps, ListItem } from '@material-ui/core'
import { ZodObject, z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import moment from 'moment'

import { debounce } from 'lodash'
import FromToDateRangePicker from './FromToDateRangePicker/FromToDateRangePicker'
import GroupInputs from '../GroupInputs/GroupInputs'
import AutoCompleteSimple from '../Autocomplete/AutoCompleteSimple'
import { loadComponent, removeNullOrUndefinedKeys, reorderFields } from './helpers'
import GroupCheckbox from '../GroupCheckbox/GroupCheckbox'
import type { CustomTooltipProps } from '../Tooltip/Tooltip'
import GroupButton, { GroupButtonLayoutGroupProps, type GroupButtonsProps } from './GroupButton/GroupButton'
import InputForm from './InputForm/InputForm'
import DateForm from './DateForm/DateForm'
import { GridLabel } from './FormSimple.styled'
import type { Props as SelectProps } from '../Select/Select'
import SelectForm from './SelectForm/SelectForm'
import MultipleValuesForm from './MultipleValuesForm/MultipleValuesForm'
import ButtonTextWithBadgeForm from './ButtonTextWithBadgeForm/ButtonTextWithBadgeForm'
import ToggleForm from './toggleForm/toggleForm'
import AddressSearchBar, { type AddressSearchOption } from '../AddressSearchBar/AddressSearchBar'
import type { AccessorialLocationType } from '@lazr/enums'
import { type Country, User } from '@/app/model'
import TimeInputForm from './TimeInputForm/TimeInputForm'
import type { CountryCode } from 'libphonenumber-js'
import GoogleAddressSearchBarForm from './GoogleAddressSearchBarForm/GoogleAddressSearchBarForm'
import { isObjectEmpty, toArray } from '../../lib/helpers'
import MarkdownForm from './MarkdownForm/MarkdownForm'
import EmailAutoComplete from './EmailAutoComplete/EmailAutoComplete'
import SingleCheckbox from './SingleCheckbox'
import TextOutlinedLabelForm from './TextOutlinedLabelForm/TextOutlinedLabelForm'
import OutlinedSelectForm from './OutlinedSelectForm/OutlinedSelectForm'
import PureCheckbox from './PureCheckbox'
import PureToggleButton from './PureToggleButton'

// import 'codemirror/lib/codemirror.css';
// import { MarkdownEditor } from '@contentful/field-editor-markdown';

interface AddressSearchOptionWithoutData<T = unknown> extends Omit<AddressSearchOption<T>, 'data'> {}

export interface FieldConfig {
    name: string
    schema?: z.ZodTypeAny
    placeholder?: string
    spaceForError?: boolean
    defaultValue?: string | number | object | string[] | boolean
    subLabel?: string
    values?: string | AddressSearchOptionWithoutData | string[] | null | any
    InputProps?: any
    required?: boolean
    disabled?: boolean
    type:
        | 'text'
        | 'textLabel'
        | 'textOutlined'
        | 'textSearchBar'
        | 'groupText'
        | 'date'
        | 'fromTo'
        | 'slider'
        | 'autocomplete'
        | 'autoComplete'
        | 'singleCheckbox'
        | 'checkbox'
        | 'groupButtons'
        | 'numberField'
        | 'numberFieldBlue'
        | 'hiddenText'
        | 'select'
        | 'multipleValues'
        | 'toggleButton'
        | 'autoCompleteWithAddressBookGoogle'
        | 'autoCompleteWithEmail'
        | 'timeField'
        | 'timeFieldOutlined'
        | 'injectComponent'
        | 'phoneNumberField'
        | 'markdown'
        | 'buttonTextWithBadge'
        | 'textOutlinedLabel'
        | 'outlinedSelectedForm'
        | 'pureCheckbox'
        | 'pureToggleButton'
    inputType?: string
    // Add type field for dynamic field types
    helperText?: string
    isFocus?: boolean
    layoutConfig?: Record<string, any>
    labelName?: string
    width?: string
    groupInputs?: Record<string, any>
    groupButtons?: GroupButtonsProps
    additionalDescription?: string
    hide?: boolean
    tooltip?: CustomTooltipProps
    select?: {
        data?: any
        name?: 'carrier' | 'iconList' | 'currency' | 'serviceLevel' | 'location'
        size?: SelectProps['size']
        extraInfo?: React.ReactNode | string
    }
    multipleValues?: {
        type: 'text' | 'textLabel' | 'textOutlined' | 'textSearchBar' | 'groupText' | 'hiddenText'
        label?: string
        placeholder?: string
        button?: string
        schema: z.ZodTypeAny
        name: string
        size?: SelectProps['size']
    }
    phoneNumber?: {
        userCountry?: CountryCode
        type: 'text' | 'textLabel' | 'textOutlined'
        size?: SelectProps['size']
        InputProps?: any
    }
    autocomplete?: {
        data: any
        name:
            | 'emailList'
            | 'countryList'
            | 'threePlAgentList'
            | 'threePlOrganizationList'
            | 'otherUserInfoList'
            | 'clientOrganizationList'
            | 'carrierList'
            | 'salesRepresentativeList'
        type: 'text' | 'textLabel' | 'textOutlined'
    }
    singleCheckbox?: {
        checkboxText: string | React.ReactNode
    }
    checkbox?: {
        content: string,
        description?: string,
        fontSize?: number,
        fontWeight?: number,
    }
    toggles?: {
        first: {
            value: string | boolean
            label: string
        }
        second: {
            value: string | boolean
            label: string
        },
        // label?: string
        // name: string
    }
    toggleButton?: {
        color: string
    },
    injectComponent?: string | ReactElement
    addressSearch?: {
        locationType?: AccessorialLocationType
        showAddressFields?: boolean
        countries?: Country[]
    }
    startIcon?: React.ReactNode
    badgeCount?: number
    hasOrder?: boolean
    order?: number
    // Add onChange function
    size?: SelectProps['size']
    onChange?: (value: any) => void
    onClick?: (value: any) => void
    hideOutBox?: boolean
    getOrder?: (data: Record<string, any>) => void
}

export interface SchemaField {
    type?: string
    label?: string
    zod?: z.ZodTypeAny
    layoutConfig: Record<string, any>
    tooltip?: CustomTooltipProps
    order?: number
}

interface ButtonSubmit extends ButtonProps {
    title: string
    disabled?: boolean
}

export interface SubmitSchema {
    button?: ButtonSubmit
    layoutConfig?: Record<string, any>
    fc: (value?: any) => void
}

export interface ResetForm {
    resetStatus: boolean
    setReset: React.Dispatch<React.SetStateAction<boolean>>
}
export interface SchemaRefineConfigurations {
    refine: (data: any) => boolean // Define the type of the refine function
    options: { message: string; path: any } // Define the type of the options object
}

export interface OnError {
    data: any
    isValid: boolean
    isDirty: boolean
}

export interface ServerError {
    name: string
    message: string
}


interface FormSimpleProps {
    fields?: FieldConfig[]
    schemaRefineConfigurations?: SchemaRefineConfigurations[]
    resetForm?: ResetForm
    submit?: SubmitSchema
    forceSubmit?: boolean
    debouncer?: { wait?: number }
    onChange?: (value: any) => void
    onError?: (value: OnError) => void
    nameID?: string
    hideForm?: boolean
    layoutConfig?: GridProps | HTMLAttributes<any>
    headerForm?: ReactElement
    serverError?: ServerError[]
    showNotification?: string
}

interface ErrorData {
    name: string
    value: string
    error: any
}




const FormSimple: React.FC<FormSimpleProps> = ({
    fields,
    submit,
    forceSubmit,
    resetForm,
    debouncer,
    onChange,
    onError,
    nameID,
    layoutConfig,
    headerForm,
    schemaRefineConfigurations,
    hideForm,
    serverError,
    showNotification,
}) => {
    // Use state to store the fields and update when the prop changes
    const [formFields, setFormFields] = useState(reorderFields(fields ?? []))

    // Update the formFields state when the fields prop changes
    useEffect(() => {
        setFormFields(reorderFields(fields ?? []))
    }, [fields])

    let schema = z.object(
        // biome-ignore lint/performance/noAccumulatingSpread:
        (fields ?? []).reduce((acc: any, field) => {
            // Only add field to accumulator if field.hide is false
            if (!field.hide) {
                acc[field.name] = field.schema
            }
            return acc
        }, {}),
    )

    if (schemaRefineConfigurations) {
        //console.debug('schemaRefineConfigurations', schemaRefineConfigurations)
        // biome-ignore lint/complexity/noForEach: <explanation>
        schemaRefineConfigurations.forEach(({ refine, options }) => {
            schema = schema.refine(refine, options) as any
        })
    }
    const [customErrors, setCustomErrors] = useState({})
    //const [serverCustomErrors, setServerCustomErrors] = useState(serverError)
    //const [mdValue, setMDValue] = useState<string | undefined>("**Hello world!!!**");

    const [formData, setFormData] = useState<Record<string, string | number>>({})
    const debouncerMilliseconds = debouncer?.wait ? debouncer.wait * 1000 : 0.3
    const {
        handleSubmit,
        control,
        formState: { errors, isValid, isDirty },
        trigger,
        reset,
        getValues,
        getFieldState,
        setValue,
        setError,
    } = useForm({
        resolver: zodResolver(schema),
        resetOptions: {
            keepDirtyValues: true, // user-interacted input will be retained
            keepErrors: true, // input errors will be retained with value update
        },
    })

    useEffect( () => {
        //console.debug('error effect')
        if (serverError?.length) {
            for( let i = 0; i < serverError?.length; i++ ) {
                //console.debug('error effect 2', serverError[i].name)
                setError(serverError[i].name, {type: 'custom', message: serverError[i].message})
            }
        }
    }, [ serverError ])

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        //console.debug('ERRORS', errors, customErrors)
        const err = [...(errors && toArray(errors)), ...(customErrors && toArray(customErrors))].filter((obj) => !isObjectEmpty(obj))
        const checkErrors = () => {
            if (Object.keys(err).length > 0) {
                return true // Indicating there are errors
            }
            return false // Indicating no errors
        }

        const hasErrors = checkErrors()
        let data = {
            data: {},
            isValid: true,
            isDirty: isDirty,
        }

        if (hasErrors) {
            data = {
                data: err,
                isValid: false,
                isDirty: isDirty,
            }
        } else {
            data = {
                data: {},
                isValid: true,
                isDirty: isDirty,
            }
        }
        onError?.(data)
    }, [errors, customErrors])

    // biome-ignore lint/correctness/useExhaustiveDependencies:
    useEffect(() => {
        if (resetForm?.resetStatus) {
            // Reset the form to its initial state
            reset({})
            for (const field of formFields) {
                setValue(field.name, '')
            }
            // Clear the formData state
            setFormData({})
            resetForm?.setReset(false)
        }
    }, [resetForm?.resetStatus])

    const handleOnChangeData = async (data: any) => {
        onChange?.(removeNullOrUndefinedKeys(data))
    }

    const handleSubmitData = async (data: any) => {
        submit?.fc?.(removeNullOrUndefinedKeys(data))
    }
    // Debounce the handleOnChangeData function
    const debouncedHandleOnChange = React.useRef(debounce(handleSubmit(handleOnChangeData), debouncerMilliseconds)).current

    useEffect(() => {
        if (forceSubmit) {
            debouncedHandleOnChange()
        }
    }, [forceSubmit])

    useEffect(() => {
        // cancel any previous debounce action (so that a slower - but later - request doesn't overtake a newer but faster request)
        debouncedHandleOnChange.cancel()
        debouncedHandleOnChange
    }, [debouncedHandleOnChange])

    const renderField = (field: FieldConfig, onchange: any, nameID?: string) => {
        const { type, tooltip, labelName, placeholder, spaceForError, onClick } = field
        if (field.hide) {
            return
        }
        switch (field.type) {
            case 'injectComponent':
                return field.injectComponent
            case 'text':
            case 'textLabel':
            case 'textOutlined':
            case 'textSearchBar':
            case 'numberField':
            case 'numberFieldBlue':
            case 'phoneNumberField':
            case 'hiddenText': {
                return (
                    <InputForm
                        errors={errors}
                        control={control}
                        field={field}
                        setValue={setValue}
                        trigger={trigger}
                        getValues={getValues}
                        debouncedHandleOnChange={onchange && debouncedHandleOnChange}
                        key={`${nameID}-${field.type}`}
                    />
                )
            }
            case 'textOutlinedLabel': {
                return (
                    <TextOutlinedLabelForm
                        errors={errors}
                        control={control}
                        field={field}
                        setValue={setValue}
                        trigger={trigger}
                        getValues={getValues}
                        debouncedHandleOnChange={onchange && debouncedHandleOnChange}
                        key={`${nameID}-${field.type}`}
                    />
                )
            }
            case 'outlinedSelectedForm': {
                return (
                    <OutlinedSelectForm
                        errors={errors}
                        control={control}
                        field={field}
                        setValue={setValue}
                        trigger={trigger}
                        getValues={getValues}
                        debouncedHandleOnChange={onchange && debouncedHandleOnChange}
                        key={`${nameID}-${field.type}`}
                    />
                )
            }
            case 'timeField':
            case 'timeFieldOutlined':
                return (
                    <TimeInputForm
                        errors={errors}
                        control={control}
                        field={field}
                        setValue={setValue}
                        trigger={trigger}
                        getValues={getValues}
                        debouncedHandleOnChange={onchange && debouncedHandleOnChange}
                        key={`${nameID}-${field.type}`}
                    />
                )
            case 'singleCheckbox':
                if (!field.singleCheckbox) throw new Error('For single checkbox, labelname is mandatory')
                const val = field.values || !!getValues(field.name);
                return (
                    <SingleCheckbox
                        name={field.name}
                        checkboxText={field.singleCheckbox.checkboxText}
                        errors={errors}
                        values={val}
                        control={control}
                        field={field}
                        setValue={setValue}
                        trigger={trigger}
                        getValues={getValues}
                        debouncedHandleOnChange={onchange && debouncedHandleOnChange}
                        key={`${nameID}-${field.type}`}
                    />
                )
            case 'checkbox':
                return (
                    <GroupCheckbox
                        hasOrder={field.hasOrder}
                        namePrefix={field.name}
                        control={control}
                        tooltip={tooltip}
                        errors={errors}
                        setValue={setValue}
                        trigger={trigger}
                        getValues={getValues}
                        defaultValue={field.defaultValue || ''}
                        debouncedHandleOnChange={onchange && debouncedHandleOnChange}
                        labelName={field.labelName}
                        groupCheckbox={field.groupInputs} // Pass each field as an array to GroupInputs
                        additionalDescription={field.additionalDescription}
                        values={field.values}
                        hideOutBox={field.hideOutBox}
                        getOrder={field.getOrder}
                    />
                )

            case 'groupText':
                return (
                    <GroupInputs
                        namePrefix={field.name}
                        control={control}
                        errors={errors}
                        tooltip={tooltip}
                        setValue={setValue}
                        trigger={trigger}
                        getValues={getValues}
                        debouncedHandleOnChange={onchange && debouncedHandleOnChange}
                        labelName={field.labelName}
                        groupInputs={field.groupInputs} // Pass each field as an array to GroupInputs
                        additionalDescription={field.additionalDescription}
                        hideOutBox={field.hideOutBox}
                    />
                )
            case 'groupButtons':
                return (
                    <>
                        <GroupButton
                            groupButtons={field?.groupButtons}
                            schema={schema}
                            control={control}
                            errors={errors}
                            setValue={setValue}
                            defaultValue={field.defaultValue}
                            trigger={trigger}
                            setCustomErrors={setCustomErrors}
                            values={field.values}
                            name={field.name}
                            debouncedHandleOnChange={onchange && debouncedHandleOnChange}
                            isDirty={isDirty}
                        />
                    </>
                )
            case 'fromTo':
                return (
                    <FromToDateRangePicker
                        defaultValue={field.defaultValue || ''}
                        namePrefix={field.name}
                        control={control}
                        errors={errors}
                        setValue={setValue}
                        trigger={trigger}
                        getValues={getValues}
                        debouncedHandleOnChange={onchange && debouncedHandleOnChange}
                        labelName={field.labelName}
                        hideOutBox={field.hideOutBox}
                    />
                )
            case 'date':
                return (
                    <DateForm
                        name={field.name}
                        label={field.labelName || ''}
                        defaultValue={field.defaultValue && typeof field.defaultValue !== 'boolean' ? moment.utc(field.defaultValue).hours(5).format('YYYY-MM-DD HH:mm:ss') : null}
                        control={control}
                        errors={errors}
                        setValue={setValue}
                        getValues={getValues}
                        trigger={trigger}
                        debouncedHandleOnChange={onchange && debouncedHandleOnChange}
                        values={field.values}
                    />
                )
            case 'slider':
                return (
                    <div>
                        <Typography id={`${field.name}-slider`} gutterBottom>
                            {field.name}
                        </Typography>

                        <Controller
                            name={field.name}
                            control={control}
                            defaultValue={field.defaultValue || 0}
                            render={({ field: { onChange, value } }) => (
                                <Slider
                                    value={value}
                                    onChange={(e, newValue) => {
                                        onChange(newValue)
                                        setValue(field.name, newValue, { shouldValidate: false })
                                        // Trigger validation on change
                                        trigger(field.name).then(() => {
                                            const isFieldValid = !errors[field.name]
                                            if (isFieldValid && onChange) {
                                                onChange(newValue)
                                                setValue(field.name, newValue, {
                                                    shouldValidate: false,
                                                })
                                            }
                                        })
                                    }}
                                    onChangeCommitted={(e, newValue) => {
                                        onChange(newValue)
                                        setValue(field.name, newValue, { shouldValidate: false })
                                        // Trigger validation on change
                                        trigger(field.name).then(() => {
                                            const isFieldValid = !errors[field.name]
                                            if (isFieldValid && onChange) {
                                                onChange(newValue)
                                                setValue(field.name, newValue, {
                                                    shouldValidate: false,
                                                })
                                                onchange && debouncedHandleOnChange()
                                            }
                                        })
                                    }}
                                    aria-labelledby={`${field.name}-slider`}
                                    valueLabelDisplay='auto'
                                />
                            )}
                        />
                        {errors[field.name] && (
                            <Typography variant='caption' color='error'>
                                {errors[field.name]?.message}
                            </Typography>
                        )}
                    </div>
                )
            case 'select': {
                return (
                    <SelectForm
                        errors={errors}
                        control={control}
                        field={field}
                        setValue={setValue}
                        trigger={trigger}
                        getValues={getValues}
                        debouncedHandleOnChange={onchange && debouncedHandleOnChange}
                        key={`${nameID}-${field.type}`}
                    />
                )
            }
            case 'toggleButton': {
                return (
                    <ToggleForm
                        errors={errors}
                        control={control}
                        field={field}
                        setValue={setValue}
                        trigger={trigger}
                        getValues={getValues}
                        debouncedHandleOnChange={onchange && debouncedHandleOnChange}
                        key={`${nameID}-${field.type}`}
                    />
                )
            }
            case 'multipleValues': {
                return (
                    <MultipleValuesForm
                        mainErrors={errors}
                        mainControl={control}
                        field={field}
                        setMainValue={setValue}
                        mainTrigger={trigger}
                        getMainValues={getValues}
                        mainDebouncedHandleSubmit={onchange && debouncedHandleOnChange}
                        key={`${nameID}-${field.type}`}
                    />
                )
            }
            case 'autoCompleteWithAddressBookGoogle': {
                return (
                    <GoogleAddressSearchBarForm
                        errors={errors}
                        control={control}
                        field={field}
                        setValue={setValue}
                        trigger={trigger}
                        getValues={getValues}
                        debouncedHandleOnChange={onchange && debouncedHandleOnChange}
                        key={`${nameID}-${field.type}`}
                    />
                )
            }
            case 'autoCompleteWithEmail': {
                return (
                    <EmailAutoComplete
                        data={field.autocomplete?.data}
                        namePrefix={field.name}
                        values={field.values}
                        control={control}
                        errors={errors}
                        setValue={setValue}
                        trigger={trigger}
                        getValues={getValues}
                        debouncedHandleOnChange={onchange && debouncedHandleOnChange}
                    />
                )
            }
            case 'autoComplete': {
                if (!field.autocomplete?.data) {
                    return
                }

                let renderOption: any = ({
                    email,
                    shortFullName,
                    organizationName,
                }: {
                    email: string
                    shortFullName: string
                    organizationName?: string
                }) => (
                    <Typography variant='caption'>
                        {shortFullName || email}
                        {organizationName && ` (${organizationName})`}
                    </Typography>
                )

                // biome-ignore lint/style/useConst: It will be used down the code
                let renderInput: any = (params: any): React.ReactNode => {
                    return <DynamicComponentText
                        {...params}
                        label={field.labelName}
                        margin='dense'
                        fullWidth
                        error={errors?.[field.name]}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                        }}
                        helperText={errors?.[field.name]?.message}
                    />
                }

                let getOptionLabel: any = ({
                    shortFullName,
                    organizationName,
                    email,
                }: {
                    shortFullName: string
                    organizationName: string
                    email: string
                }) => `${shortFullName || email}${organizationName ? ` (${organizationName})` : ''}`
                let val = getValues(field.name)
                if (field.defaultValue) {
                    val = field.autocomplete?.data.find((item: any) => {
                        return item?.id === field.defaultValue
                    })
                }

                if (field.values) {
                    if (field.autocomplete?.name === 'carrierList') {
                        val = field.autocomplete?.data.find((item: any) => {
                            return item?.name === field.values
                        })
                    } else if (field.autocomplete?.name === 'countryList') {
                        val = field.autocomplete?.data.find((item: any) => {
                            return item?.name === field.values.name
                        })
                    } else {
                        val = field.autocomplete?.data.find((item: any) => {
                            return item?.id === field.values
                        })
                    }
                }

                // biome-ignore lint/style/useConst: It will be used down the code
                let getOptionSelected: any = (option: any, selectedValue: any) => {
                    return option?.id === selectedValue?.id
                }

                const DynamicComponentText: any = loadComponent(field.autocomplete?.type ? field.autocomplete?.type : 'text')
                let mt;
                if (
                    field.autocomplete?.name === 'clientOrganizationList' ||
                    field.autocomplete?.name === 'threePlOrganizationList' ||
                    field.autocomplete?.name === 'carrierList' ||
                    field.autocomplete?.name === 'countryList'
                ) {
                    renderOption = ({ name }: { name: string }) => <Typography variant='caption'>{name}</Typography>
                    getOptionLabel = ({ name }: { name: string }) => name

                    if (field.autocomplete?.name === 'countryList') {
                        mt = 0
                    }

                    if (field.autocomplete?.name === 'carrierList' ||
                        field.autocomplete?.name === 'countryList'
                    ) {
                        if (field.defaultValue) {
                            val = field.autocomplete?.data.find((item: any) => {
                                return item?.name === field.defaultValue
                            })
                        }
                    }
                } else if (field.autocomplete?.name === 'threePlAgentList') {
                    renderOption = ({ email, longFullName }: { email: string; longFullName: string }) => (
                        <Typography variant='caption'>{longFullName || email}</Typography>
                    )

                    getOptionLabel = ({ longFullName, email }: { email: string; longFullName: string }) => `${longFullName || email}`
                }

                return (
                    <>
                        <AutoCompleteSimple
                            mt={mt}
                            data={field.autocomplete?.data}
                            defaultValue={val ? val : ''}
                            namePrefix={field.name}
                            tooltip={field.tooltip}
                            values={val}
                            control={control}
                            errors={errors}
                            setValue={setValue}
                            trigger={trigger}
                            getValues={getValues}
                            debouncedHandleOnChange={onchange && debouncedHandleOnChange}
                            getOptionSelected={getOptionSelected}
                            renderOption={renderOption}
                            getOptionLabel={getOptionLabel}
                            renderInput={renderInput}
                        />
                    </>
                )
            }
            case 'markdown': {
                return <>
                    {/* <MarkdownEditor isInitiallyDisabled={false} sdk={} minHeight={400}/> */}
                    <MarkdownForm
                        field={field}
                        errors={errors}
                        control={control}
                        setValue={setValue}
                        trigger={trigger}
                        getValues={getValues}
                        debouncedHandleOnChange={onchange && debouncedHandleOnChange}
                        key={`${nameID}-${field.type}`}
                    />
                </>
            }
            case 'buttonTextWithBadge':
                return (
                    <ButtonTextWithBadgeForm
                        errors={errors}
                        control={control}
                        field={field}
                        setValue={setValue}
                        trigger={trigger}
                        getValues={getValues}
                        debouncedHandleOnChange={onchange && debouncedHandleOnChange}
                        key={`${nameID}-${field.type}`}
                        onClick={onClick}
                    />
                )
            case 'pureCheckbox':
                return <PureCheckbox
                    errors={errors}
                    control={control}
                    field={field}
                    setValue={setValue}
                    trigger={trigger}
                    getValues={getValues}
                    debouncedHandleOnChange={onchange && debouncedHandleOnChange}
                    key={`${nameID}-${field.type}`}
                    onChange={onChange}
                />
            case 'pureToggleButton':
                return <PureToggleButton
                    errors={errors}
                    control={control}
                    field={field}
                    setValue={setValue}
                    trigger={trigger}
                    getValues={getValues}
                    debouncedHandleOnChange={onchange && debouncedHandleOnChange}
                    key={`${nameID}-${field.type}`}
                    onChange={onChange}
                />
            default:
                return null
        }
    }

    const DynamicComponentButton: any = loadComponent(submit?.button?.type ? submit.button?.type : 'primaryButton')

    const gridAll = (
        <Grid container spacing={4} justifyContent={'flex-start'} {...layoutConfig}>
            {headerForm && headerForm}
            {formFields.map((field) => {
                const hideField = field.hide ? field.hide : false
                if (!hideField) {
                    if (field.type === 'hiddenText') {
                        return renderField(field, onChange, `${nameID}-${field.name}`)
                    }

                    return (
                        <GridLabel item {...field.layoutConfig} key={`${nameID}-${field.name}`}>
                            {renderField(field, onChange)}
                        </GridLabel>
                    )
                }
                return
            })}
            {submit && (
                <GridLabel container item {...submit?.layoutConfig}>
                    <DynamicComponentButton type='submit' {...submit.button} disabled={!isDirty || !isValid || submit.button?.disabled}> {/*onClick={() => submit?.fc()}*/}
                        {' '}
                        {submit?.button?.title || 'Submit'}{' '}
                    </DynamicComponentButton>
                </GridLabel>
            )}
        </Grid>
        /* <Notifications open={showNotification} */
    )

    return !hideForm ? (
        <form onSubmit={submit ? handleSubmit(handleSubmitData) : () => {}} id={nameID}>
            {gridAll}
        </form>
    ) : (
        gridAll
    )

    // return (
    //     <form id={nameID}>
    //         <Grid container spacing={2}>
    //             {formFields.map((field) => {
    //                 const hideField = field.hide ? field.hide : false
    //                 return (
    //                     !hideField && (
    //                         <Grid item {...field.layoutConfig} key={field.name}>
    //                             {renderField(field, onChange)}
    //                         </Grid>
    //                     )
    //                 )
    //             })}
    //             {submit && (
    //                 <Grid item {...submit?.layoutConfig}>
    //                     <DynamicComponentButton type='submit' {...submit.button}>
    //                         {' '}
    //                         {submit?.button?.title || 'Submit'}{' '}
    //                     </DynamicComponentButton>
    //                 </Grid>
    //             )}
    //         </Grid>
    //     </form>
    // )
}

export default FormSimple
