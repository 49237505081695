import React, { ReactChildren, ReactElement, useState, useEffect } from 'react'

import i18n from './AppHeader.i18n'
import { useI18n } from '@/app/ui/components/hooks/I18n'

import CurrencyMenu from '@/app/ui-new/components/CurrencyMenu/CurrencyMenu'
import LanguageMenu from '@/app/ui-new/components/LanguageMenu/LanguageMenu'
import SecondaryButton from '@/app/ui-new/components/SecondaryButton/SecondaryButton'
import { Box, Hidden, SwipeableDrawer } from '@material-ui/core'
import { FontAwesomeIcon as ButtonIcon } from '@fortawesome/react-fontawesome'
import { IconName } from '@fortawesome/fontawesome-common-types'
import { ImpersonateMenu } from '@/app/ui-new/components/Navigation/AppHeader/components/ImpersonateMenu'
import { MARKETPLACE_ROUTES, NEW_MARKETPLACE_ROUTES } from '@/app/ui/lib/constants'

import { MarketPlaceStep as NewMarketPlaceStep } from '@/app/ui-new/definitions/MarketPlace'

import { MarketPlaceStep } from '@/app/ui/definitions/MarketPlace'
import { NotificationsMenu } from '@/app/ui-new/components/Navigation/AppHeader/components/NotificationsMenu'
import { User } from '@/app/model/User'
import { UserMenu } from '@/app/ui-new/components/Navigation/AppHeader/components/UserMenu'
import { UIAccess, UserRole, Onboarding } from '@lazr/enums'
// import { getMarketPlace as reduxGetMarketPlace } from '@/app/ui-new/redux/selectors/MarketPlaceSelectors'

import { getMarketPlace as reduxGetMarketPlace } from '@/app/ui/redux/selectors/MarketPlaceSelectors'
import { getMarketPlace as reduxGetNewMarketPlace } from '@/app/ui-new/redux/selectors/MarketPlaceSelectors'

import { getUser as reduxGetUser, getUserOrganization as reduxGetUserOrganization } from '@/app/ui/redux/selectors'
import { initialMarketPlace } from '@/app/ui-new/redux/store/InitialState'
import { useSelector } from 'react-redux'
import {
    Grid,
    Header,
    Link,
    Logo,
    NavButton,
    NavButtonSelected,
    UtilitiesBox,
    Tooltip,
    Typography,
    GridMobile,
    IconButton,
    GMXButton,
} from './AppHeader.styled'
import Badge from '@/app/ui-new/components/Badge/Badge'

import { initialMarketPlace as newInitialMarketPlace } from '@/app/ui-new/redux/store/InitialState'
import { BadgeText } from '../../Badge/Badge.styled'
import { Menu as MenuIcon } from '@material-ui/icons'
import { Close as MenuClose } from '@material-ui/icons'

const AppHeader: React.FunctionComponent<HeaderProps> = (props) => {
    const { t } = useI18n(i18n)

    // const marketplace = useSelector(reduxGetMarketPlace)
    // const currentUser = useSelector(reduxGetUser)
    // const marketPlaceStep = localStorage.getItem('newMarketPlaceStep')

    // const reduxLastCompletedStep = marketplace.lastCompletedStep
    //     ? parseInt(String(marketplace.lastCompletedStep), 10)
    //     : initialMarketPlace.lastCompletedStep

    // const localStorageMarketplaceStep = marketPlaceStep ? parseInt(marketPlaceStep, 10) : initialMarketPlace.lastCompletedStep

    // const nextStep: MarketPlaceStep = Math.max(reduxLastCompletedStep, localStorageMarketplaceStep)
    // const isFinished = nextStep === MarketPlaceStep.THANK_YOU

    // const reduxOrderId = marketplace.order?.id ?? ''
    // const orderId = reduxOrderId || localStorage.getItem('newMarketPlaceOrderId')

    // let marketplaceUrl = NEW_MARKETPLACE_ROUTES[MarketPlaceStep.LOAD_AND_TERMS]

    // if (orderId && !isFinished) {
    //     if (marketplace.isPrepared && NEW_MARKETPLACE_ROUTES[nextStep] === NEW_MARKETPLACE_ROUTES[MarketPlaceStep.LOAD_AND_TERMS]) {
    //         marketplaceUrl = `${NEW_MARKETPLACE_ROUTES[nextStep]}/?preparedSearchId=${orderId}`
    //     } else {
    //         marketplaceUrl = `${NEW_MARKETPLACE_ROUTES[nextStep]}/${orderId}`
    //     }
    // }

    const marketplace = useSelector(reduxGetMarketPlace)
    const currentUser = useSelector(reduxGetUser)
    const marketPlaceStep = localStorage.getItem('marketPlaceStep')

    const reduxLastCompletedStep = marketplace.lastCompletedStep
        ? parseInt(String(marketplace.lastCompletedStep), 10)
        : initialMarketPlace.lastCompletedStep
    const localStorageMarketplaceStep = marketPlaceStep ? parseInt(marketPlaceStep, 10) : initialMarketPlace.lastCompletedStep
    const nextStep: MarketPlaceStep = Math.max(reduxLastCompletedStep, localStorageMarketplaceStep) + 1

    const reduxOrderId = marketplace.order?.id ?? ''
    const orderId = reduxOrderId || localStorage.getItem('marketPlaceOrderId')

    let marketplaceUrl = MARKETPLACE_ROUTES[MarketPlaceStep.SHIPMENT_INFORMATION]
    if (orderId) {
        if (marketplace.isPrepared && MARKETPLACE_ROUTES[nextStep] === MARKETPLACE_ROUTES[MarketPlaceStep.SHIPMENT_INFORMATION]) {
            marketplaceUrl = `${MARKETPLACE_ROUTES[nextStep]}/?preparedSearchId=${orderId}`
        } else {
            marketplaceUrl = `${MARKETPLACE_ROUTES[nextStep]}/${orderId}`
        }
    }

    // start temp new marketplace code
    const newMarketplace = useSelector(reduxGetNewMarketPlace)
    const newMarketPlaceStep = localStorage.getItem('newMarketPlaceStep')

    const reduxNewLastCompletedStep = newMarketplace.lastCompletedStep
        ? parseInt(String(newMarketplace.lastCompletedStep), 10)
        : newInitialMarketPlace.lastCompletedStep

    const localStorageNewMarketplaceStep = newMarketPlaceStep
        ? parseInt(newMarketPlaceStep, 10)
        : newInitialMarketPlace.lastCompletedStep

    const newNextStep: NewMarketPlaceStep = Math.max(reduxNewLastCompletedStep, localStorageNewMarketplaceStep)
    const isFinished = newNextStep === NewMarketPlaceStep.THANK_YOU

    const reduxNewOrderId = newMarketplace.order?.id ?? ''
    const newOrderId = reduxNewOrderId || localStorage.getItem('newMarketPlaceOrderId')

    let newMarketplaceUrl = NEW_MARKETPLACE_ROUTES[NewMarketPlaceStep.LOAD_AND_TERMS]

    if (newOrderId && !isFinished) {
        if (
            newMarketplace.isPrepared &&
            NEW_MARKETPLACE_ROUTES[newNextStep] === NEW_MARKETPLACE_ROUTES[NewMarketPlaceStep.LOAD_AND_TERMS]
        ) {
            newMarketplaceUrl = `${NEW_MARKETPLACE_ROUTES[newNextStep]}/?preparedSearchId=${newOrderId}`
        } else {
            newMarketplaceUrl = `${NEW_MARKETPLACE_ROUTES[newNextStep]}/${newOrderId}`
        }
    }

    const userOrganization = useSelector(reduxGetUserOrganization)

    /* GMX SPECIAL CASE */
    const isGmxImport = userOrganization?.onboarding === Onboarding.GMX_IMPORT

    const [showGmxWarning, setShowGmxWarning] = useState<boolean>(false)

    const isOrganizationAdmin =
        (currentUser?.permissions.organizationRole.role === UserRole.ADMIN || currentUser?.getImpersonatingInfo().isAdmin) ?? false

    const showImpersonationMenu =
        !!props.user && !!userOrganization && (userOrganization.isSystem() || props.user.getImpersonatingInfo().active)

    const navButtons = [
        {
            label: t('System Dashboard'),
            icon: 'chart-line',
            layoutConfig: { width: '154px' },
            section: '/system-dashboard',
            href: '/system-dashboard',
            visible: userOrganization?.isSystem(),
        },
        {
            label: t('Orders'),
            icon: 'file-alt',
            section: '/orders',
            href: '/orders',
            visible: userOrganization ? [UIAccess.ALL, UIAccess.V1].includes(userOrganization?.uiAccess) : false,
        },
        {
            label: `${t('Orders')}`,
            icon: 'file-alt',
            section: '/new/orders',
            href: '/new/orders',
            visible: userOrganization ? [UIAccess.ALL, UIAccess.V2].includes(userOrganization?.uiAccess) : false,
        },

        {
            label: `${t('Schedule Pickup')}`,
            icon: 'calendar',
            section: '/new/schedule-pickup',
            href: '/new/schedule-pickup',
            visible: userOrganization ? [UIAccess.ALL, UIAccess.V2].includes(userOrganization?.uiAccess) : false,
        },
        {
            label: t('Marketplace'),
            icon: 'truck-moving',
            section: '/marketplace/shipment-info',
            href: marketplaceUrl,
            visible: userOrganization
                ? [UIAccess.ALL, UIAccess.V1].includes(userOrganization?.uiAccess) &&
                  !(
                      !currentUser?.permissions.performSearch ||
                      (!currentUser?.permissions.allowCollectPaymentTerms && !currentUser?.permissions.allowPrepaidPaymentTerms)
                  )
                : false,
        },
        {
            label: t('Marketplace'),
            icon: 'truck-moving',
            section: '/new/marketplace',
            href: newMarketplaceUrl,
            visible: userOrganization
                ? [UIAccess.ALL, UIAccess.V2].includes(userOrganization?.uiAccess) &&
                  !(
                      !currentUser?.permissions.performSearch ||
                      (!currentUser?.permissions.allowCollectPaymentTerms && !currentUser?.permissions.allowPrepaidPaymentTerms)
                  )
                : false,
        },
        {
            label: t('Invoices'),
            icon: 'file-invoice-dollar',
            section: '/invoices',
            href: '/invoices',
            visible: currentUser?.permissions.viewCompanyInvoices,
        },
        {
            label: t('Invoice CSV Batches'),
            layoutConfig: { width: '162px' },
            icon: 'receipt',
            section: '/invoice-csv-batches',
            href: '/invoice-csv-batches',
            visible: userOrganization?.isSystem(),
        },
        {
            label: t('Tracking Updates CSV Batches'),
            layoutConfig: { width: '225px' },
            icon: 'map-marked-alt',
            section: '/tracking-update-csv-batches',
            href: '/tracking-update-csv-batches',
            visible: userOrganization?.isSystem(),
        },
        {
            label: t('Address Book'),
            icon: 'address-book',
            section: '/addressbook',
            href: '/addressbook',
            visible: currentUser?.permissions.viewCompanyAddressBook,
        },
        {
            label: t('Organizations'),
            icon: 'sitemap',
            section: '/organizations',
            href: '/organizations',
            visible: userOrganization?.isSystem(),
        },
        {
            label: t('My Organization'),
            icon: 'users',
            section: '/my-organization',
            href: '/my-organization',
            visible: userOrganization?.isSystem() || isOrganizationAdmin,
        },
        {
            label: t('EDI'),
            icon: 'download',
            section: '/edi',
            href: '/edi',
            visible: !(userOrganization?.isClient() && !userOrganization?.hasEdiSupport),
        },
        {
            label: `${t('Agreement')}`,
            icon: 'file-contract',
            section: '/new/agreements',
            href: '/new/agreements',
            visible: userOrganization?.isSystem(),
        },
    ]

    const [mobileOpen, setMobileOpen] = useState<boolean>(false)

    const drawerToggle = (): void => {
        setMobileOpen(!mobileOpen)
    }

    useEffect(() => {
        setShowGmxWarning(true)
    }, [])
    return (
        <>
            <SwipeableDrawer open={mobileOpen} onClose={drawerToggle} onOpen={drawerToggle}>
                <div style={{ width: '60px', paddingTop: '15px' }}>
                    <GridMobile container id='navbar-mobile' justifyContent='center' spacing={3}>
                        <IconButton color='inherit' aria-label='Close drawer' onClick={drawerToggle} id='close-mobile'>
                            <MenuClose />
                        </IconButton>
                        {navButtons.map((b, i) => {
                            if (!b.visible) {
                                return null
                            }

                            const isSelected =
                                window.location.pathname.startsWith(b.section) || window.location.pathname.startsWith(`/temp${b.section}`)
                            const showLayoutConfig = isSelected ? b.layoutConfig : null
                            return (
                                <Grid container alignItems='center' key={`navbar-mobile-${i}`}>
                                    <Box className={` navbar-mobile-menu-item ${isSelected ? 'selected' : 'truck-calendar-link'}`}>
                                        <Link to={b.href}>
                                            <Tooltip title={b.label} placement={'bottom'}>
                                                <ButtonIcon icon={['far', b.icon as IconName]} />
                                            </Tooltip>
                                            {/* {isSelected && <Typography variant='body2'>{b.label}</Typography>} */}
                                        </Link>
                                    </Box>
                                </Grid>
                            )
                        })}
                        {isGmxImport && (
                            <>
                                <GMXButton
                                    href='https://gmxworldwide.com/index.php/Shipments/ShipmentLog'
                                    style={{ color: 'black', marginLeft: '0px' }}
                                >
                                    <Tooltip title={t('Go to GMX')} placement={'bottom'}>
                                        <span>GMX</span>
                                    </Tooltip>
                                </GMXButton>
                            </>
                        )}
                    </GridMobile>
                </div>
            </SwipeableDrawer>
            <Header>
                <Grid container alignItems='center' id='navbar'>
                    <Grid item container xs={3} sm={5} md={3} lg={8} alignItems='center'>
                        <Hidden lgUp>
                            <IconButton color='inherit' aria-label='Open drawer' onClick={drawerToggle}>
                                <MenuIcon />
                            </IconButton>
                        </Hidden>
                        <Link to='/'>
                            <Logo />
                        </Link>
                        <Hidden mdDown>
                            {navButtons.map((b, i) => {
                                if (!b.visible) {
                                    return null
                                }

                                const isSelected =
                                    window.location.pathname.startsWith(b.section) ||
                                    window.location.pathname.startsWith(`/temp${b.section}`)

                                return (
                                    <Box className={`navbar-menu-item ${isSelected ? 'selected' : ''}`} key={i}>
                                        <Link to={b.href}>
                                            <Tooltip title={b.label} placement={'bottom'}>
                                                <ButtonIcon icon={['far', b.icon as IconName]} />
                                            </Tooltip>
                                            {isSelected && <Typography variant='body2'>{b.label}</Typography>}
                                        </Link>
                                    </Box>
                                )
                            })}
                            {isGmxImport && (
                                <>
                                    {' '}
                                    <GMXButton href='https://gmxworldwide.com/index.php/Shipments/ShipmentLog'>
                                        {t('Go to GMX')}
                                    </GMXButton>{' '}
                                </>
                            )}
                        </Hidden>
                    </Grid>
                    <Grid item container xs={9} sm={7} md={9} lg={4} justifyContent='flex-end'>
                        {props.user && (
                            <Box ml={2}>
                                <CurrencyMenu user={props.user} />
                            </Box>
                        )}
                        {props.user && (
                            <Box ml={2}>
                                <LanguageMenu user={props.user} />
                            </Box>
                        )}
                        <UtilitiesBox ml={4}>
                            {!!props.user && showImpersonationMenu && (
                                <ImpersonateMenu user={props.user} userOrganization={userOrganization} />
                            )}
                            <NotificationsMenu />
                            <UserMenu user={props.user} userOrganization={userOrganization} />
                        </UtilitiesBox>
                    </Grid>
                </Grid>
            </Header>
        </>
    )
}

AppHeader.displayName = 'Header'
export { AppHeader }

export interface HeaderProps {
    user: User | null
}
