import React, { useEffect, useState } from 'react'
import { loadComponent } from '../helpers'
import { Control, Controller, FieldValues } from 'react-hook-form'
import FormSimple, { FieldConfig } from '../FormSimple'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, Typography } from '@material-ui/core'
import GroupButtonSelectionCard, { GroupButtonSelectionCardProps } from './GroupButtonSelectionCard/GroupButtonSelectionCard'
import { ButtonCardCheckboxColor } from '../GroupButton/GroupButton'

export interface CardCheckboxProps {
    value: string
    layoutConfig?: Record<string, string>
    component?: React.ReactElement
}

export interface CardCheckboxFormProps {
    name: string
    mainName: string
    title: string | React.ReactNode
    content: string | number
    mainControl: Control<FieldValues>
    control: Control<FieldValues>
    color: ButtonCardCheckboxColor
    setValue: (name: string, value: any, options?: any) => void
    getValues: (name?: string, options?: any) => void
    trigger: (name: string) => Promise<boolean>
    debouncedHandleOnChange: () => void
    value: any
    selectable: boolean
    isDirty: boolean
    isValid: boolean
    formSimple?: FieldConfig[]
    errors?: any
    error?: any
    helperText?: any
    getButton?: (parentId: string, buttonId: string) => void
    setError?: any
    setCustomErrors?: any
}

const CardCheckboxForm: React.FC<CardCheckboxFormProps> = ({
    name,
    mainName,
    title,
    content,
    color,
    mainControl,
    control,
    errors,
    error,
    helperText,
    setValue,
    getValues,
    trigger,
    debouncedHandleOnChange,
    value,
    selectable,
    isDirty,
    formSimple,
    getButton,
    isValid,
    setError,
    setCustomErrors,
}) => {
    const [isValidForm, setIsValidForm] = useState(false)
    const [isDirtyForm, setIsDirtyForm] = useState(false)
    const [startForm, setStartForm] = useState(false)

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setValue(`${mainName}.${name}.value`, value ? value : false, {
            shouldValidate: false,
            shouldDirty: false,
        })
        setValue(`${mainName}.${name}.proved`, false, {
            shouldValidate: false,
            shouldDirty: false,
        })

        setIsDirtyForm(false)
        setStartForm(false)
        debouncedHandleOnChange?.()
    }, [value])

    
    return (
        <Controller
            key={`${mainName}.${name}`}
            name={`${mainName}.${name}.value`}
            control={control}
            render={({ field }) => {
                const errorField = errors?.[field.name]

                const onClick = async (e?: any) => {
                    const inputValue = !field.value
                    if (field.onChange) {
                        field.onChange(inputValue)
                        setValue(`${field.name}`, inputValue, {
                            shouldValidate: true,
                            shouldDirty: true,
                        })
                    }
                    // Trigger validation on change
                    trigger(field.name).then(() => {
                        if (field.onChange) {
                            setStartForm(false)
                            field.onChange(inputValue)

                            setValue(`${field.name}`, inputValue, {
                                shouldValidate: true,
                                shouldDirty: true,
                            })

                            setValue(`${mainName}.${name}.proved`, formSimple ? false : true)
                            getButton?.(mainName, name)

                            debouncedHandleOnChange()
                        }
                    })
                }

                const onClose = (e?: any) => {
                    const inputValue = !field.value
                    if (field.onChange) {
                        field.onChange(inputValue)
                        setValue(`${field.name}`, inputValue, {
                            shouldValidate: false,
                            shouldDirty: true,
                        })
                    }
                    // Trigger validation on change
                    trigger(field.name).then(() => {
                        if (field.onChange) {
                            field.onChange(inputValue)
                            setValue(`${field.name}`, inputValue, {
                                shouldValidate: false,
                                shouldDirty: true,
                            })
                            getButton?.(mainName, name)
                            setValue(`${mainName}.${name}.proved`, false)
                            debouncedHandleOnChange()
                        }
                    })
                    setIsDirtyForm(false)
                    setStartForm(false)
                }

                const expandedContent = formSimple && (
                    <div style={{ padding: '16px' }}>
                        <FormSimple
                            fields={formSimple}
                            submit={{
                                button: { title: 'Submit' },
                                fc: (data: any) => {
                                    if (data) {
                                        setValue(`${mainName}.${name}.proved`, true, {
                                            shouldValidate: false,
                                            shouldDirty: true,
                                        })

                                        setValue(`${mainName}.${name}.value`, field.value, {
                                            shouldValidate: false,
                                            shouldDirty: true,
                                        })
                                        setValue(`${mainName}.${name}.formSimple`, data)
                                        setIsValidForm(true)
                                        setIsDirtyForm(true)
                                        setStartForm(true)

                                        debouncedHandleOnChange?.()
                                    }
                                },
                            }}
                            onError={(data: any) => {
                                if (!data.isValid) {
                                    setValue(`${mainName}.${name}.proved`, false, {
                                        shouldValidate: false,
                                        shouldDirty: false,
                                    })

                                    setStartForm(true)
                                    setIsValidForm(false)
                                    setIsDirtyForm(true)
                                }
                            }}
                        />
                    </div>
                )

                return (
                    <>
                        <GroupButtonSelectionCard
                            icon={<FontAwesomeIcon icon={['fas', 'truck']} style={{ fontSize: '17.6px' }} />}
                            title={title}
                            description={content}
                            checked={!!field.value}
                            onClick={onClick}
                            errorMessage={error?.message}
                            unexpandedWidth='100%'
                            expandedContent={formSimple ? expandedContent : undefined}
                            expandOnClick={!!formSimple}
                            onClose={formSimple ? onClose : undefined}
                            unexpandedHeight={formSimple ? '110px' : ''}
                            error={formSimple && isDirtyForm ? !isValidForm : !!error} // !!error  ? true : false,
                            hideCheckIcon={formSimple ? (!isDirtyForm ? false : undefined) : undefined}
                            checkIcon={formSimple ? (isValidForm ? undefined : true) : undefined}
                            labelStyle={
                                formSimple
                                    ? !startForm
                                        ? { backgroundColor: 'white' }
                                        : { backgroundColor: '' }
                                    : { backgroundColor: 'auto' }
                            }

                            // {...selectCardProps}
                        />
                        {isDirty && errorField && (
                            <Typography variant='caption' style={{ color: 'red' }}>
                                {errorField?.message}
                            </Typography>
                        )}
                    </>
                )
            }}
        />
    )
}

export default CardCheckboxForm
