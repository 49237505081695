import { Typography as MuiTypography } from "@material-ui/core"
import styled, { ThemedStyledProps } from 'styled-components';
import { spacing as MuiSpacing } from "@material-ui/system";
import MuiSwitch from "@/app/ui-new/components/Switch/Switch";

const getTrackStyle = (props: ThemedStyledProps<any, any>): string => {
    if (props.disabled) {
        return ''
    }
    return `background-color: ${props.$color ?? props.theme.palette.primary.main} !important;
            opacity: 1;
    `
}

export const Switch = styled(styled(MuiSwitch)(MuiSpacing))<{$color?: string}>`
    & [class*="MuiSwitch-switchBase"] {
        &.Mui-checked {
            & [class*="MuiSwitch-thumb"]{
                // width: 12px;
                // height: 12px;
            }
            & + [class*="MuiSwitch-track"]{
                ${(props): string => getTrackStyle(props)};
                border: none;
            }
        }
    }
`

export const ServiceTypography = styled(styled(MuiTypography)(MuiSpacing))<{$fontSize?: number, $fontWeight?: number}>`
    font-size: ${(props): number => props.$fontSize ?? 12}px;
    font-weight: ${(props): number => props.$fontWeight ?? 500};
    line-height: 1.2rem;
`