import React, { useState } from "react";
import { Typography, ButtonIcon, Tooltip } from './TextWithTooltip.styled'
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface Props {
    text?: string
    tooltip: {
        title: React.ReactNode | string
        icon: IconProp
    } | null
    fontSize?: number
    fontWeight?: number
    color?: string
}

const TextWithTooltip: React.FunctionComponent<Props> = ({
    text,
    tooltip,
    fontSize,
    fontWeight,
    color
}) => {
    const [open, setOpen] = useState<boolean>(false);
    return <>
        <Typography
            ml={1}
            $fontSize={fontSize} 
            $fontWeight={fontWeight} 
            $color={color}
        >
            {text}
            {tooltip && <Tooltip
                open={open}
                title={tooltip.title ?? ''} 
                placement={'right'}
            >
                <ButtonIcon ml={1} icon={tooltip.icon} />
            </Tooltip>}
        </Typography>
    </>;
}

export default TextWithTooltip;