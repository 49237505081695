/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReturnOrderRequest } from '../models/ReturnOrderRequest';
import type { SuccessResponse_ReturnOrderResponse_ } from '../models/SuccessResponse_ReturnOrderResponse_';
import { request as __request } from '../core/request';

export class OrderReturnService {

    /**
     * return order
     * @param requestBody
     * @returns SuccessResponse_ReturnOrderResponse_ OK
     * @throws ApiError
     */
    public static async returnOrderIdHttpController(
        requestBody: ReturnOrderRequest,
    ): Promise<SuccessResponse_ReturnOrderResponse_> {
        const result = await __request({
            method: 'POST',
            path: `/return-order`,
            body: requestBody,
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Internal server error`,
            },
        });
        return result.body;
    }

}