import {
    Typography as MuiTypography,
    Tooltip as MuiTooltip
} from '@material-ui/core';
import styled from 'styled-components';
import { spacing as MuiSpacing } from '@material-ui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Typography = styled(styled(MuiTypography)(MuiSpacing))<{$fontSize?: number, $fontWeight?: number, $color?: string}>`
    font-size: ${(props): number => props.$fontSize ?? 16}px;
    font-weight: ${(props): number => props.$fontWeight ?? 500};
    color: ${(props): string => props.$color ?? props.theme.palette.black.main};
    display: inline-block;
`

export const ButtonIcon = styled(styled(FontAwesomeIcon)(MuiSpacing))<{$color?: string}>`
    margin-right: 2px;
    margin-top: 4px;
    color: ${(props): string => props.$color ?? props.theme.palette.black.main};
`

export const Tooltip = styled(styled(MuiTooltip)(MuiSpacing))`
`