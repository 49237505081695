import React, { useEffect, useRef, useState } from 'react'
import { TextField, Typography, RelativeContainer, InputLabel } from './TextOutlinedLabelForm.styled'
import { FormControl, InputAdornment, Tooltip } from '@material-ui/core'
import { type Control, Controller, type FieldValues } from 'react-hook-form'
import type { FieldConfig } from '../FormSimple'
import TextWithTooltip from '../../TextWithTooltip/TextWithTooltip'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons-lazr-alias'

export interface InputProps {
    value: string
    layoutConfig?: Record<string, string>
    component?: React.ReactElement
}

export interface InputFormProps {
    name?: string
    control: Control<FieldValues>
    setValue: (name: string, value: any, options?: any) => void
    getValues: (name?: string, options?: any) => void
    trigger: (name: string) => Promise<boolean>
    debouncedHandleOnChange: () => void
    field: FieldConfig
    errors?: any
    error?: any
    helperText?: any
}

const TextOutlinedLabelForm: React.FC<InputFormProps> = ({
    name,
    field,
    control,
    errors,
    error,
    helperText,
    setValue,
    getValues,
    trigger,
    debouncedHandleOnChange,
}) => {

    const { type, values, labelName, spaceForError, disabled, required, size, InputProps, helperText:textHelperText, isFocus:isDefaultFocus, layoutConfig, inputType } = field
    const [ isFocus, setIsFocus ] = useState<boolean>(!!values);
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setValue(name ? name : field.name, values)
        // debouncedHandleOnChange?.()
    }, [field.values])
    return (
        <Controller
            name={name ? name : field.name}
            control={control}
            defaultValue={field.defaultValue || ''}
            render={({ field:controlField }) => {
                const errorField = error ? error : errors?.[controlField.name]
                const helperTextField = textHelperText ? textHelperText : errors?.[controlField.name]?.message ?? spaceForError ?? ' '
                return (
                    <RelativeContainer>
                        <InputLabel $backgroundColor={layoutConfig?.labelColor}>
                            <>
                                <TextWithTooltip
                                    fontSize={12}
                                    fontWeight={600}
                                    text={labelName} 
                                    tooltip={{icon: faCircleQuestion as IconProp, title: '123488'}} 
                                />
                                {field.required && !field.disabled && <Typography
                                    ml={1}
                                    $fontSize={14} 
                                    $fontWeight={600}
                                    $color={'black'}
                                >*</Typography>}
                            </>
                        </InputLabel>
                        <TextField
                            variant='outlined'
                            isFocus={isFocus}
                            disabled={disabled}
                            size={size}
                            value={values ?? ''}
                            required={!!required}
                            type={type === 'hiddenText' ? 'hidden' : (inputType ?? 'text')}
                            fullWidth
                            error={!!errorField}
                            helperText={helperTextField}
                            onFocus={() => setIsFocus(true)}
                            onBlur={() => setIsFocus(false)}
                            InputProps={(isFocus || values) && InputProps}
                            onChange={(e: any) => {
                                const inputValue = e.target.value
                                const value = inputValue === '' ? 'null' : inputValue;
                                if (controlField.onChange) {
                                    controlField.onChange(value)
                                    setValue(controlField.name, value, {
                                        shouldValidate: false,
                                    })
                                    debouncedHandleOnChange?.()
                                }

                                // Trigger validation on change
                                trigger(controlField.name).then(() => {
                                    const isFieldValid = !errorField
                                    if (isFieldValid && controlField.onChange) {
                                        controlField.onChange(value)
                                        setValue(controlField.name, value, {
                                            shouldValidate: false,
                                        })
                                        debouncedHandleOnChange?.()
                                    }
                                })
                            }}
                        />
                    </RelativeContainer>
                )
            }}
        />
    )
}

export default TextOutlinedLabelForm
