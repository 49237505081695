/* eslint-disable @typescript-eslint/quotes, max-len, quote-props */
export default {
    ['Accountant']: 'Accountant',
    ['Admin']: 'Admin',
    ['Agent']: 'Agent',
    ['Billing']: 'Billing',
    ['Guest Organization']: 'Guest Organization',
    ['Guest']: 'Guest',
    ['Imp:']: 'Imp:',
    ['Invite Members']: 'Invite Members',
    ['My Account']: 'My Account',
    ['My Organization']: 'My Organization',
    ['Settings']: 'Settings',
    ['Permissions']: 'Permissions',
    ['Orders']: 'Orders',
    ['Org:']: 'Org:',
    ['Profile']: 'Profile',
    ['Role:']: 'Role:',
    ['Shipper']: 'Shipper',
    ['Sign Out']: 'Sign Out',
    ['Supplier']: 'Supplier',
    ['User']: 'User',
    ['Users']: 'Users',
    ['Invoices']: 'Invoices'
}
