import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import async, { asyncComponentType } from '../components/Async'
import DashboardLayout from '../layouts/Dashboard'
import { ConnectedComponent } from 'react-redux'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import MarketPlaceProgressSummary from '../pages/marketplace/components/ProgressSummary'
import Website from '@/app/ui-new/pages/website'
import Landing from '@/app/ui-new/pages/landing'
import RootLayout from '@/app/ui-new/layouts/RootLayout'
import WithFooterLayout from '@/app/ui-new/layouts/WithFooterLayout'
import WithoutFooterLayout from '@/app/ui-new/layouts/WithoutFooterLayout'
//import AppLayout from '@/app/ui-new/layouts/AppLayout'
import AuthMain from '@/app/ui-new/pages/auth/AuthMain'
import MarketPlaceLayout from '@/app/ui-new/layouts/MarketPlaceLayout'

import NewLayout from '@/app/ui-new/layouts/NewLayout'

// Public components
const PrivacyPolicy = async(() => import('@/app/ui-new/pages/public/PrivacyPolicy'))
const TermsOfService = async(() => import('@/app/ui-new/pages/public/TermsOfService'))
const HelpCenter = async(() => import('@/app/ui-new/pages/public/HelpCenter'))
const OrderTracking = async(() => import('@/app/ui-new/pages/public-tracking'))

// Auth components
const SignOut = async(() => import('@/app/ui-new/pages/auth/SignOut'))
const RegisterUser = async(() => import('@/app/ui-new/pages/auth/RegisterUser'))
const ResetPassword = async(() => import('@/app/ui-new/pages/auth/ResetPassword'))
const VerifyEmail = async(() => import('@/app/ui-new/pages/auth/VerifyEmail'))
const CreateAccount = async(() => import('@/app/ui-new/pages/auth/CreateAccount'))

// Error components
import Page404 from '@/app/ui-new/pages/error/Page404'
import NewCreditApplication from '@/app/ui-new/pages/credit-application/New'
import NewSchedulePickup from '@/app/ui-new/pages/schedule-pickup/New'
import BlankLayout from '@/app/ui-new/layouts/BlankLayout'

// Dashboards components
const UserDashboard = async(() => import('../pages/dashboards/Client'))
const SystemDashboard = async(() => import('../pages/dashboards/System'))

// Orders components
const OrdersList = async(() => import('../pages/orders/List'))
const OrderDetails = async(() => import('../pages/orders/Details'))
const ConsolidationPayment = async(() => import('../pages/consolidation-payment/view'))

const AccountProfile = async(() => import('../pages/account/Profile'))

// Address components
const AddressBook = async(() => import('../pages/addresses/Book'))
const NewAddress = async(() => import('../pages/addresses/New'))

// Marketplace components
const NewQuote = async(() => import('../pages/marketplace/New'))
const MarketplaceMarketplace = async(() => import('../pages/marketplace/Marketplace'))
const MarketPlaceConfirmation = async(() => import('../pages/marketplace/Confirmation'))
const MarketplaceCompleteOrder = async(() => import('../pages/marketplace/CompleteOrder'))

// New Marketplace components
// const Load = async(() => import('@/app/ui-new/pages/marketplace/Load/Load'))
const Load = async(() => import('@/app/ui-new/pages/marketplace/LoadV2/index'))
const OriginAndDestination = async(() => import('@/app/ui-new/pages/marketplace/OriginAndDestination/OriginAndDestination'))
const Coverage = async(() => import('@/app/ui-new/pages/marketplace/Coverage/Coverage'))

const Results = async(() => import('@/app/ui-new/pages/marketplace/Results/Results'))
const AdditionalDetails = async(() => import('@/app/ui-new/pages/marketplace/AdditionalDetails/AdditionalDetails'))
const Checkout = async(() => import('@/app/ui-new/pages/marketplace/Checkout/Checkout'))
const ThankYou = async(() => import('@/app/ui-new/pages/marketplace/ThankYou/ThankYou'))

// Organizations components
const OrganizationsList = async(() => import('../pages/organizations/List'))

// Organizations components
const OrganizationManagement = async(() => import('../pages/organization-management/List'))

// Users components
const Organization = async(() => import('../pages/users/List'))

// Agreements component
const AgreementsList = async(() => import('@/app/ui-new/pages/agreement/List'))
const AgreementsNew = async(() => import('@/app/ui-new/pages/agreement/New'))

const AgreementsEdit = async(() => import('@/app/ui-new/pages/agreement/Edit'))

// EDI components
const EdiOrderList = async(() => import('../pages/edi/List'))
const EdiOrderView = async(() => import('../pages/edi/View'))

// Invoices components
const InvoicesList = async(() => import('../pages/invoice/List'))
const Invoice = async(() => import('../pages/invoice/Details'))
const NewConsolidation = async(() => import('../pages/invoice/New'))
const StatementOfAccount = async(() => import('../pages/invoice/StatementOfAccount'))

// Invoice CSV Batch components
const InvoiceCsvBatchList = async(() => import('../pages/invoice-csv-batch/List'))
const InvoiceCsvBatch = async(() => import('../pages/invoice-csv-batch/Details'))

// Tracking Update CSV Batch components
const TrackingUpdateCsvBatchList = async(() => import('../pages/tracking-update-csv-batch/List'))
const TrackingUpdateCsvBatch = async(() => import('../pages/tracking-update-csv-batch/Details'))

// Notifications components
const NotificationsList = async(() => import('../pages/notifications/List'))

// Style Guide components
const StyleGuide = async(() => import('@/app/ui-new/pages/style-guide'))

// GMX ONBOARDING
const GmxOnboarding = async(() => import('@/app/ui-new/pages/auth/GmxOnboarding'))
// const GMXArchive = async(() => import('@/app/ui-new/pages/gmx-archive/List'))
const uuidv4Pattern = '[A-Fa-f0-9]{8}-[A-Fa-f0-9]{4}-4[A-Fa-f0-9]{3}-[89ABab][A-Fa-f0-9]{3}-[A-Fa-f0-9]{12}'
const uuidv5Pattern = '[A-Fa-f0-9]{8}-[A-Fa-f0-9]{4}-5[A-Fa-f0-9]{3}-[89ABab][A-Fa-f0-9]{3}-[A-Fa-f0-9]{12}'

// New Order
const OrderList = async(() => import('@/app/ui-new/pages/order/List'))

// SCHEDULE PICKUP
const SchedulePickupList = async(() => import('@/app/ui-new/pages/schedule-pickup/List'))


export const landingRoute: AppRoute = {
    id: 'Landing',
    path: '/landing',
    containsHome: true,
    component: Landing,
    layout: RootLayout,
    hide: true,
    exact: true,
}

export const commerceChamberLandingRoute: AppRoute = {
    id: 'CommerceChamberLanding',
    path: '/commerce-chamber/landing',
    containsHome: true,
    component: Landing,
    layout: RootLayout,
    hide: true,
    exact: true,
}

export const homeRoute: AppRoute = {
    id: 'Home',
    path: '/',
    containsHome: true,
    component: Website,
    layout: WithFooterLayout,
    hide: true,
    exact: true,
}

export const privacyPolicyRoute: AppRoute = {
    id: 'Privacy Policy',
    name: 'Privacy Policy',
    path: '/privacy',
    component: PrivacyPolicy,
    layout: WithFooterLayout,
}

export const helpCenterRoute: AppRoute = {
    id: 'Help Center',
    name: 'Help Center',
    path: '/helpcenter',
    component: HelpCenter,
    layout: WithFooterLayout,
}

export const termsOfServiceRoute: AppRoute = {
    id: 'Terms of Service',
    name: 'Terms of Service',
    path: '/terms',
    component: TermsOfService,
    layout: WithFooterLayout,
}

export const signInRoute: AppRoute = {
    id: 'Sign In',
    name: 'Sign In',
    path: '/signin',
    component: AuthMain,
    layout: WithoutFooterLayout,
}

export const registerOrganizationRoute: AppRoute = {
    id: 'Register Organization',
    name: 'Register Organization',
    path: '/register',
    component: CreateAccount,
    layout: WithoutFooterLayout,
    exact: true,
}

export const registerOrganizationWithAgentRoute: AppRoute = {
    id: 'Register Organization with rep',
    name: 'Register Organization with rep',
    path: '/register/rep/:repName([a-zA-Z]+)',
    component: CreateAccount,
    layout: WithoutFooterLayout,
}

export const registerUserRoute: AppRoute = {
    id: 'Register User',
    name: 'Register User',
    path: '/register/:slug([0-9a-z-]+)',
    component: RegisterUser,
    layout: WithoutFooterLayout,
}

export const registerSupplierRoute: AppRoute = {
    id: 'Register Supplier',
    name: 'Register Supplier',
    path: '/register/:slug([0-9a-z-]+)/supplier',
    component: RegisterUser,
    layout: WithoutFooterLayout,
}

export const resetPasswordRoute: AppRoute = {
    id: 'Reset Password',
    name: 'Reset Password',
    path: '/reset-password',
    component: ResetPassword,
    layout: WithoutFooterLayout,
}

export const verifyEmailRoute: AppRoute = {
    id: 'Verify Email',
    name: 'Verify Email',
    path: '/verify-email',
    component: VerifyEmail,
    layout: WithoutFooterLayout,
}

export const signOutRoute: AppRoute = {
    id: 'Authenticated Auth Routes',
    name: 'Sign Out',
    path: '/signout',
    component: SignOut,
    layout: RootLayout,
    hide: true,
}

export const accountProfileRoute: AppRoute = {
    id: 'Profile',
    name: 'Profile',
    path: '/account/profile',
    component: AccountProfile,
    layout: DashboardLayout,
}

/* REGISTER 2024 */
export const createAccountRoute: AppRoute = {
    id: 'Create Your Organization',
    name: 'Create Your Organization',
    path: '/create-your-organization',
    component: CreateAccount,
    layout: WithoutFooterLayout,
}

/* GMX */
export const gmxOnboardingRoute: AppRoute = {
    id: 'GMX Onboarding',
    name: 'Create Account',
    path: `/gmx/:id(${uuidv4Pattern})`,
    component: GmxOnboarding,
    layout: WithoutFooterLayout,
}

export const dashboardRoute: AppRoute = {
    id: 'Dashboard',
    name: 'Dashboard',
    path: '/dashboard',
    iconProp: [ 'fal', 'chart-line' ],
    component: UserDashboard,
    layout: DashboardLayout,
    hide: true,
}

export const systemDashboardRoute: AppRoute = {
    id: 'System Dashboard',
    name: 'System Dashboard',
    path: '/system-dashboard',
    iconProp: [ 'fal', 'chart-line' ],
    component: SystemDashboard,
    layout: DashboardLayout,
    exact: true,
}

export const orderListRoute: AppRoute = {
    id: 'Orders',
    name: 'Orders',
    path: '/orders',
    iconProp: [ 'fal', 'file-alt' ],
    component: OrdersList,
    layout: DashboardLayout,
}

export const orderDetailsRoute: AppRoute = {
    id: 'Order Details',
    name: 'Order Details',
    path: `/order/:id(${uuidv4Pattern})`,
    iconProp: [ 'fal', 'file-alt' ],
    component: OrderDetails,
    layout: DashboardLayout,
    hide: true,
}

export const invoiceListRoutes: AppRoute = {
    id: 'Invoices',
    name: 'Invoices',
    path: '/invoices',
    iconProp: [ 'fal', 'file-invoice-dollar' ],
    component: InvoicesList,
    layout: DashboardLayout,
    permissionKey: 'viewCompanyInvoices',
    notification: true,
}

export const consolidationPaymentRoute: AppRoute = {
    id: 'Consolidation Payment',
    name: 'Consolidation Payment',
    path: '/consolidation-payment',
    component: ConsolidationPayment,
    layout: DashboardLayout,
    permissionKey: 'viewCompanyInvoices',
}

export const newMarketplaceStart: AppRoute = {
    id: 'Marketplace Load & Terms',
    name: 'Marketplace Load & Terms',
    path: '/new/marketplace/load-and-terms/:id(${uuidv4Pattern}',
    iconProp: [ 'fal', 'truck-moving' ],
    component: Load,
    layout: MarketPlaceLayout,
    hide: true,
}

export const newOrderListRoute: AppRoute = {
    id: 'Order List',
    name: 'Order List',
    path: '/new/orders/',
    iconProp:  ['fal', 'file-alt' ],
    component: OrderList,
    layout: NewLayout,

}
// Add it after when Martin is done
// export const gmxArchiveListRoute: AppRoute = {
//     id: 'GMX Archive List',
//     name: 'GMX Archive List',
//     path: '/new/gmx-archive/',
//     iconProp:  ['fal', 'file-alt' ],
//     component: GMXArchive,
//     layout: OrderLayout,

// }

export const creditApplicationAdd: AppRoute = {
    id: 'Credit Application Add',
    name: 'Credit Application Add',
    path: `/new/credit-application/new/:id(${uuidv4Pattern})`,
    iconProp: [ 'fal', 'truck-moving' ],
    component: NewCreditApplication,
    layout: NewLayout,
    hide: true,
}
export const newSchedulePickupAdd: AppRoute = {
    id: 'Schedule Pickup Add',
    name: 'Schedule Pickup Add',
    path: '/new/schedule-pickup/new/',
    iconProp: [ 'fal', 'truck-moving' ],
    component: NewSchedulePickup,
    layout: NewLayout,
    hide: true,
}

// export const orderDetailsRoute: AppRoute = {
//     id: 'Order Details',
//     name: 'Order Details',
//     path: `/order/:id(${uuidv4Pattern})`,
//     iconProp: [ 'fal', 'file-alt' ],
//     component: OrderDetails,
//     layout: DashboardLayout,
//     hide: true,
// }



export const newSchedulePickupListRoute: AppRoute = {
    id: 'Schedule Pickup List',
    name: 'Schedule Pickup List',
    path: '/new/schedule-pickup/',
    //iconProp:  ['fal', 'truck-ramp-box' ],
    iconProProp:  'truck-ramp-box',
    component: SchedulePickupList,
    layout: NewLayout,

}


export const newMarketplaceShippingDetailsRoute: AppRoute = {
    id: 'Marketplace Shipping Details',
    name: 'Marketplace Shipping Details',
    path: `/new/marketplace/shipping-details/:id(${uuidv4Pattern})?`,
    iconProp: [ 'fal', 'truck-moving' ],
    component: Load,
    layout: MarketPlaceLayout,
    hide: true,
}



export const newMarketplaceLoadRoute: AppRoute = {
    id: 'Marketplace Load & Terms',
    name: 'Marketplace Load & Terms',
    path: `/new/marketplace/load-and-terms/:id(${uuidv4Pattern})?`,
    iconProp: [ 'fal', 'truck-moving' ],
    component: Load,
    layout: MarketPlaceLayout,
}

export const newMarketplaceOriginAndDestinationRoute: AppRoute = {
    id: 'Marketplace Origin & Destination',
    name: 'Marketplace Origin & Destination',
    path: `/new/marketplace/origin-and-destination/:id(${uuidv4Pattern})?`,
    component: OriginAndDestination,
    layout: MarketPlaceLayout,
}

export const newMarketplaceCoverageRoute: AppRoute = {
    id: 'Marketplace Coverage',
    name: 'Marketplace Coverage',
    path: `/new/marketplace/coverage/:id(${uuidv4Pattern})`,
    component: Coverage,
    layout: MarketPlaceLayout,
}

export const newMarketplaceResultsRoute: AppRoute = {
    id: 'Marketplace Results',
    name: 'Marketplace Results',
    path: `/new/marketplace/results/:id(${uuidv4Pattern})`,
    component: Results,
    layout: MarketPlaceLayout,
}

export const newMarketplaceAdditionalDetailsRoute: AppRoute = {
    id: 'Marketplace Additional Details',
    name: 'Marketplace Additional Details',
    path: `/new/marketplace/additional-details/:id(${uuidv4Pattern})`,
    component: AdditionalDetails,
    layout: MarketPlaceLayout,
}

export const newMarketplaceCheckoutRoute: AppRoute = {
    id: 'Marketplace Checkout',
    name: 'Marketplace Checkout',
    path: `/new/marketplace/checkout/:id(${uuidv4Pattern})`,
    component: Checkout,
    layout: MarketPlaceLayout,
}

export const newMarketplaceThankYouRoute: AppRoute = {
    id: 'Marketplace Thank You',
    name: 'Marketplace Thank You',
    path: `/new/marketplace/thank-you/:id(${uuidv4Pattern})`,
    component: ThankYou,
    layout: MarketPlaceLayout,
}

export const newMarketplaceShipmentInfoRoute: AppRoute = {
    id: 'MarketplaceNew',
    name: 'New Marketplace',
    path: `/new/marketplace/load-and-terms/:id(${uuidv4Pattern})?`,
    component: Load,
    layout: MarketPlaceLayout,
}

export const marketplaceShipmentInfoRoute: AppRoute = {
    id: 'Marketplace',
    name: 'Marketplace',
    path: `/marketplace/shipment-info/:id(${uuidv4Pattern})?`,
    iconProp: [ 'fal', 'truck-moving' ],
    component: NewQuote,
    contentFooter: MarketPlaceProgressSummary,
    layout: DashboardLayout,
}

export const marketplaceMarketplaceRoute: AppRoute = {
    id: 'Marketplace Quotes',
    name: 'Marketplace Quotes',
    path: `/marketplace/quotes/:id(${uuidv4Pattern})`,
    component: MarketplaceMarketplace,
    contentFooter: MarketPlaceProgressSummary,
    layout: DashboardLayout,
}

export const marketplaceCompleteOrderRoute: AppRoute = {
    id: 'Marketplace Complete Order',
    name: 'Marketplace Complete Order',
    path: `/marketplace/complete-order/:id(${uuidv4Pattern})`,
    component: MarketplaceCompleteOrder,
    contentFooter: MarketPlaceProgressSummary,
    layout: DashboardLayout,
}

export const marketplaceConfirmationRoute: AppRoute = {
    id: 'Marketplace Confirmation',
    name: 'Marketplace Confirmation',
    path: `/marketplace/confirmation/:id(${uuidv4Pattern})`,
    component: MarketPlaceConfirmation,
    contentFooter: MarketPlaceProgressSummary,
    layout: DashboardLayout,
}

export const addressBookRoute: AppRoute = {
    id: 'Address Book',
    name: 'Address Book',
    path: '/addressbook',
    iconProp: [ 'fal', 'address-book' ],
    component: AddressBook,
    layout: DashboardLayout,
    permissionKey: 'viewCompanyAddressBook',
}

export const invoiceRoutes: AppRoute = {
    id: 'Invoice Preview',
    name: 'Invoice Preview',
    path: `/invoice/:id(${uuidv4Pattern})`,
    iconProp: [ 'fal', 'file-alt' ],
    component: Invoice,
    layout: DashboardLayout,
    hide: true,
    permissionKey: 'viewCompanyInvoices',
}

export const invoiceCsvBatchListRoute: AppRoute = {
    id: 'Invoice CSV Batches',
    name: 'Invoice CSV Batches',
    path: '/invoice-csv-batches',
    iconProp: [ 'fal', 'receipt' ],
    component: InvoiceCsvBatchList,
    layout: DashboardLayout,
    permissionKey: 'viewCompanyInvoices',
}

export const invoiceCsvBatchRoute: AppRoute = {
    id: 'Invoice CSV Batch',
    name: 'Invoice CSV Batch',
    path: `/invoice-csv-batch/:id(${uuidv4Pattern})`,
    iconProp: [ 'fal', 'receipt' ],
    component: InvoiceCsvBatch,
    layout: DashboardLayout,
    hide: true,
    permissionKey: 'viewCompanyInvoices',
}

export const trackingUpdateCsvBatchListRoute: AppRoute = {
    id: 'Tracking Update CSV Batches',
    name: 'Tracking Update CSV Batches',
    path: '/tracking-update-csv-batches',
    iconProp: [ 'fal', 'map-marked-alt' ],
    component: TrackingUpdateCsvBatchList,
    layout: DashboardLayout,
    permissionKey: 'viewCompanyInvoices',
}

export const trackingUpdateCsvBatchRoute: AppRoute = {
    id: 'Tracking Update CSV Batch',
    name: 'Tracking Update CSV Batch',
    path: `/tracking-update-csv-batch/:id(${uuidv4Pattern})`,
    iconProp: [ 'fal', 'map-marked-alt' ],
    component: TrackingUpdateCsvBatch,
    layout: DashboardLayout,
    hide: true,
    permissionKey: 'viewCompanyInvoices',
}

export const statementOfAccountRoutes: AppRoute = {
    id: 'Statement of Account',
    name: 'Statement of Account',
    path: '/invoice/statementOfAccount',
    iconProp: [ 'fal', 'file-alt' ],
    component: StatementOfAccount,
    layout: DashboardLayout,
    hide: true,
    permissionKey: 'viewCompanyInvoices',
}

export const addressNewRoute: AppRoute = {
    id: 'New Address',
    name: 'New Address',
    path: '/address/new',
    iconProp: [ 'fal', 'address-book' ],
    component: NewAddress,
    layout: DashboardLayout,
    hide: true,
    permissionKey: 'viewCompanyAddressBook',
}

export const consolidationNewRoute: AppRoute = {
    id: 'New Consolidation',
    name: 'New Consolidation',
    path: '/invoice/new',
    iconProp: [ 'fal', 'file-alt' ],
    component: NewConsolidation,
    layout: DashboardLayout,
    hide: true,
    permissionKey: 'viewCompanyInvoices',
}

export const addressDetailsRoute: AppRoute = {
    id: 'Address Details',
    name: 'Address Details',
    path: `/address/:id(${uuidv4Pattern})`,
    component: NewAddress,
    layout: DashboardLayout,
    hide: true,
    permissionKey: 'viewCompanyAddressBook',
}

export const ediOrderListRoute: AppRoute = {
    id: 'EDI Orders',
    name: 'EDI Orders',
    path: '/edi',
    iconProp: [ 'fal', 'download' ],
    component: EdiOrderList,
    layout: DashboardLayout,
    exact: true,
}

export const ediOrderViewRoute: AppRoute = {
    id: 'EDI Order',
    name: 'EDI Order',
    path: `/edi/:id(${uuidv5Pattern})`,
    component: EdiOrderView,
    layout: DashboardLayout,
    hide: true,
}

export const organizationsRoute: AppRoute = {
    id: 'Organizations',
    name: 'Organizations',
    path: '/organizations',
    iconProp: [ 'fal', 'sitemap' ],
    component: OrganizationsList,
    layout: DashboardLayout,
}

export const organizationManagementRoute: AppRoute = {
    id: 'OrganizationManagement',
    name: 'Organization Management',
    path: '/organization-management',
    component: OrganizationManagement,
    layout: DashboardLayout,
}

export const organizationRoute: AppRoute = {
    id: 'Organization',
    name: 'Organization',
    path: '/my-organization',
    iconProp: [ 'fal', 'users' ],
    component: Organization,
    layout: DashboardLayout,
}

export const agreementNewRoute: AppRoute = {
    id: 'New Agreement',
    name: 'New Agreement',
    path: '/new/agreement/new',
    iconProp: [ 'fal', 'users' ],
    component: AgreementsNew,
    layout: NewLayout,
}


export const agreementEditRoute: AppRoute = {
    id: 'Edit Agreement',
    name: 'Edit Agreement',
    path: `/new/agreement/edit/:id(${uuidv4Pattern})`,
    iconProp: [ 'fal', 'users' ],
    component: AgreementsEdit,
    layout: NewLayout,
}


export const notificationsListRoute: AppRoute = {
    id: 'Notifications',
    name: 'Notifications',
    path: '/notifications',
    component: NotificationsList,
    layout: DashboardLayout,
    exact: true,
    hide: true,
}

export const styleGuideRoute: AppRoute = {
    id: 'Style Guide',
    name: 'Style Guide',
    path: '/style-guide',
    component: StyleGuide,
    layout: DashboardLayout,
}

export const orderTrackingRoute: AppRoute = {
    id: 'Order Tracking',
    name: 'Order Tracking',
    path: `/tracking/:id(${uuidv4Pattern})`,
    component: OrderTracking,
    layout: WithoutFooterLayout,
}

export const error404Route: AppRoute = {
    id: 'Error 404',
    name: 'Error 404',
    path: '/error/404',
    component: Page404,
    layout: RootLayout,
    hide: true,
}

export const agreementsListRoute: AppRoute = {
    id: 'Agreements',
    name: 'Agreements',
    path: '/new/agreements',
    iconProp: [ 'fal', 'file-alt' ],
    component: AgreementsList,
    layout: NewLayout,
}

export interface AppRouteChild {
    path: string
    name: string
    badge?: React.ReactNode
    containsHome?: boolean
    component: React.FunctionComponent<Record<string, unknown> & RouteComponentProps> | asyncComponentType
    layout: ConnectedComponent<React.FunctionComponent<any>, any> | React.FunctionComponent | asyncComponentType
    hide?: boolean
    exact?: boolean
    permissionKey?: string
}

export interface LayoutProps {
    contentFooter?: React.ElementType
    children?: React.ReactNode
}

export interface AppRoute {
    id: string
    path: string
    name?: string
    iconProp?: IconProp
    iconProProp?: string
    notification?: boolean
    badge?: React.ReactNode
    containsHome?: boolean
    component?: ConnectedComponent<React.FunctionComponent<any>, any>
    | React.FunctionComponent<Record<string, unknown> & RouteComponentProps>
    | asyncComponentType
    layout?: React.FunctionComponent<LayoutProps> | asyncComponentType
    contentFooter?: React.ElementType
    hide?: boolean
    exact?: boolean
    children?: AppRouteChild[] | null
    permissionKey?: string
}

export const publicRoutes: AppRoute[] = [
    privacyPolicyRoute,
    termsOfServiceRoute,
    helpCenterRoute,
    newMarketplaceStart,
    newMarketplaceLoadRoute,
    newMarketplaceOriginAndDestinationRoute,
    newMarketplaceCoverageRoute,
    newMarketplaceResultsRoute,
    newMarketplaceAdditionalDetailsRoute,
    newMarketplaceCheckoutRoute,
    newMarketplaceThankYouRoute,
    newMarketplaceShipmentInfoRoute,
    marketplaceShipmentInfoRoute,
    marketplaceMarketplaceRoute,
    marketplaceConfirmationRoute,
    marketplaceCompleteOrderRoute,
    orderTrackingRoute,
]

export const unauthenticatedRoutes: AppRoute[] = [
    homeRoute,
    landingRoute,
    signInRoute,
    registerOrganizationRoute,
    registerOrganizationWithAgentRoute,
    registerUserRoute,
    resetPasswordRoute,
    verifyEmailRoute,
    commerceChamberLandingRoute,
    createAccountRoute,
    gmxOnboardingRoute,
]

export const authenticatedRoutes: AppRoute[] = [
    homeRoute,
    landingRoute,
    dashboardRoute,
    invoiceListRoutes,
    invoiceRoutes,
    statementOfAccountRoutes,
    orderListRoute,
    orderDetailsRoute,
    addressBookRoute,
    addressNewRoute,
    consolidationNewRoute,
    addressDetailsRoute,
    organizationsRoute,
    organizationRoute,
    agreementNewRoute,
    notificationsListRoute,
    signOutRoute,
    accountProfileRoute,
    organizationManagementRoute,
    error404Route,
    newOrderListRoute,
    creditApplicationAdd,
    newSchedulePickupAdd,
    newSchedulePickupListRoute,
    agreementsListRoute,
    agreementEditRoute,
    commerceChamberLandingRoute,
    // gmxArchiveListRoute,
]

export const ediRoutes: AppRoute[] = [
    ediOrderListRoute,
    ediOrderViewRoute,
]

export const systemRoutes: AppRoute[] = [
    systemDashboardRoute,
    consolidationPaymentRoute,
    styleGuideRoute,
    invoiceCsvBatchListRoute,
    invoiceCsvBatchRoute,
    trackingUpdateCsvBatchListRoute,
    trackingUpdateCsvBatchRoute,
]

export const allRoutes: AppRoute[] = [
    homeRoute,
    landingRoute,
    dashboardRoute,
    systemDashboardRoute,
    orderListRoute,
    newOrderListRoute,
    creditApplicationAdd,
    newSchedulePickupAdd,
    newSchedulePickupListRoute,
    orderDetailsRoute,
    orderTrackingRoute,
    marketplaceShipmentInfoRoute,
    marketplaceMarketplaceRoute,
    marketplaceConfirmationRoute,
    marketplaceCompleteOrderRoute,
    newMarketplaceStart,
    newMarketplaceLoadRoute,
    newMarketplaceOriginAndDestinationRoute,
    newMarketplaceCoverageRoute,
    newMarketplaceResultsRoute,
    newMarketplaceAdditionalDetailsRoute,
    newMarketplaceCheckoutRoute,
    newMarketplaceThankYouRoute,
    newMarketplaceShipmentInfoRoute,
    invoiceRoutes,
    statementOfAccountRoutes,
    invoiceListRoutes,
    invoiceCsvBatchListRoute,
    invoiceCsvBatchRoute,
    trackingUpdateCsvBatchListRoute,
    trackingUpdateCsvBatchRoute,
    addressBookRoute,
    addressNewRoute,
    consolidationNewRoute,
    addressDetailsRoute,
    organizationsRoute,
    organizationRoute,
    notificationsListRoute,
    ediOrderListRoute,
    ediOrderViewRoute,
    signInRoute,
    registerOrganizationRoute,
    registerOrganizationWithAgentRoute,
    registerUserRoute,
    resetPasswordRoute,
    accountProfileRoute,
    verifyEmailRoute,
    signOutRoute,
    error404Route,
    privacyPolicyRoute,
    termsOfServiceRoute,
    consolidationPaymentRoute,
    styleGuideRoute,
    organizationManagementRoute,
    agreementsListRoute,
    agreementEditRoute,
    commerceChamberLandingRoute,
    createAccountRoute,
    gmxOnboardingRoute,
    // gmxArchiveListRoute
]

export default allRoutes
