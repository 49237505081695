import { ApiError, ErrorResponse, OpenAPI, UuidV4 } from '@lazr/openapi-client'
import { CarrierApiService } from './CarrierApiService'
import { CountryApiService } from './CountryApiService'
import { CurrencyApiService } from './CurrencyApiService'
import { EventApiService } from './EventApiService'
import { GenderApiService } from './GenderApiService'
import { LanguageApiService } from './LanguageApiService'
import { LocaleApiService } from './LocaleApiService'
import { TimezoneApiService } from './TimezoneApiService'
import { UserApiService } from './UserApiService'
import { OrganizationApiService } from './OrganizationApiService'
import { OrderApiService } from './OrderApiService'
import { AddressApiService } from './AddressApiService'
import { AccessorialApiService } from './AccessorialApiService'
import { PackageTypeApiService } from './PackageTypeApiService'
import { BillingChargeTypeApiService } from './BillingChargeTypeApiService'
import { BillingApiService } from './BillingApiService'
import { TrackingApiService } from './TrackingApiService'
import { CoverageApiService } from './CoverageApiService'
import { SearchApiService } from './SearchApiService'
import { ExchangeRateApiService } from './ExchangeRateApiService'
import { CoverageCommodityTypeApiService } from './CoverageCommodityTypeApiService'
import * as config from '../../config'
import { DocumentApiService } from './DocumentApiService'
import { BolGeneratorApiService } from './BolGeneratorApiService'
import { LoadTenderGeneratorApiService } from './LoadTenderGeneratorApiService'
import { LabelGeneratorApiService } from './LabelGeneratorApiService'
import { UserTitleApiService } from './UserTitleApiService'
import { BillDutiesToPartyTypeApiService } from './BillDutiesToPartyTypeApiService'
import { CustomsBrokerApiService } from './CustomsBrokerApiService'
import { HazmatClassTypeApiService } from './HazmatClassTypeApiService'
import { HazmatModeTypeApiService } from './HazmatModeTypeApiService'
import { ImportExportTypeApiService } from './ImportExportTypeApiService'
import { TransportTypeApiService } from './TransportTypeApiService'
import { InvoiceApiService } from './InvoiceApiService'
import { EdiApiService } from './EdiApiService'
import { PageNavigationApiService } from './PageNavigationApiService'
import { UserEventNotificationApiService } from './UserEventNotificationApiService'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { signInRoute } from '../../ui/routes'
import { logger } from '../../logger'
import { ReasonForExportTypeApiService } from './ReasonForExportTypeApiService'
import { NonDeliveryHandlingTypeApiService } from './NonDeliveryHandlingTypeApiService'
import { IncotermsTypeApiService } from './IncotermsTypeApiService'
import { MarketPlaceStepNavigationApiService } from './MarketPlaceStepNavigationApiService'
import { InvoiceCsvBatchApiService } from './InvoiceCsvBatchApiService'
import { B13aFilingOptionApiService } from './B13aFilingOptionApiService'
import { ExportComplianceStatementApiService } from './ExportComplianceStatementApiService'
import { TrackingUpdateCsvBatchApiService } from './TrackingUpdateCsvBatchApiService'
import { PickupApiService } from './PickupApiService'
import { GmxRegistrationApiService } from './GmxRegistrationApiService'
import { OrderMarketplaceApiService } from './OrderMarketplaceApiService'
import  { CreditApplicationApiService } from './CreditApplicationApiService'

const getToken = async (): Promise<string> => {
    let encodedToken = localStorage.getItem('lazrCognitoToken') ?? ''

    if (encodedToken) {
        const decodedToken = jwtDecode<JwtPayload & { accessToken: string }>(encodedToken, { header: true })

        if (decodedToken) {
            const accessToken = jwtDecode<JwtPayload>(decodedToken.accessToken)

            if (accessToken) {
                const includeMS = (time: number) => time * 1000
                const isTokenExpired = accessToken.exp && (Date.now() > includeMS(accessToken.exp))

                if (isTokenExpired) {
                    const res = await fetch(`${OpenAPI.BASE}/refresh-token`, {
                        body: JSON.stringify({ token: encodedToken }),
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                    const body = await res.json()
                    if (body.success) {
                        logger.info('Access token was refreshed')
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                        localStorage.setItem('lazrCognitoToken', body.data.token)
                        encodedToken = body.data.token
                    } else {
                        logger.error('Access token could not be refreshed')
                        localStorage.removeItem('lazrCognitoToken')
                        encodedToken = ''
                        window.location.href = signInRoute.path
                    }
                }
            }
        }
    }

    return encodedToken
}

OpenAPI.BASE = config.httpApiUrl
OpenAPI.TOKEN = getToken

interface LocalStorageImpersonatedRoleInfo {
    organizationId: string | null
    organizationName: string | null
    isAdmin: boolean
}

export const localStorageHelper = {
    setImpersonatedRoleInfo: (role: LocalStorageImpersonatedRoleInfo): void => {
        localStorage.setItem('impersonatedRole', JSON.stringify(role))
    },
    getImpersonatedRoleInfo: (): LocalStorageImpersonatedRoleInfo => {
        const impersonatedRole = localStorage.getItem('impersonatedRole')

        if (!impersonatedRole) {
            return {
                organizationId: null,
                organizationName: null,
                isAdmin: false,
            }
        }

        return JSON.parse(impersonatedRole)
    },
}

export const impersonateRequests = (organizationId: UuidV4, organizationName: string, isAdmin: boolean): void => {
    localStorageHelper.setImpersonatedRoleInfo({
        organizationId,
        organizationName,
        isAdmin,
    })

    OpenAPI.HEADERS = {
        ...OpenAPI.HEADERS,
        'impersonate-organization': organizationId,
        'impersonate-role': isAdmin ? 'admin' : 'basic',
    }
}

export const clearImpersonateRequests = (): void => {
    localStorage.removeItem('impersonatedRole')
    delete OpenAPI.HEADERS
}

export const switchedOrganizationRequests = (organizationId: UuidV4): void => {
    OpenAPI.HEADERS = {
        ...OpenAPI.HEADERS,
        'switched-organization': organizationId,
    }
}

export const clearSwitchedOrganizationRequests = (): void => {
    delete OpenAPI.HEADERS
}

export const signIn = (token: string): void => {
    localStorage.setItem('lazrCognitoToken', token)
}

export const isSignedIn = (): boolean => !!localStorage.getItem('lazrCognitoToken')

export const signOut = (): void => {
    clearImpersonateRequests()
    localStorage.removeItem('lazrCognitoToken')
}

export const handleUnauthorizedException = (error: Error): void => {
    if (error instanceof ApiError) {
        const errorResponse = error.body as ErrorResponse
        if (errorResponse.error?.message === 'Unauthorized'){
            logger.error('Unauthorized call, sign out and redirect to log-in')
            signOut()
            window.location.href = signInRoute.path
        }
    }
}

export {
    CarrierApiService,
    CountryApiService,
    CurrencyApiService,
    EventApiService,
    GenderApiService,
    LanguageApiService,
    LocaleApiService,
    TimezoneApiService,
    UserApiService,
    OrganizationApiService,
    OrderApiService,
    AddressApiService,
    AccessorialApiService,
    PackageTypeApiService,
    BillingChargeTypeApiService,
    BillingApiService,
    TrackingApiService,
    CoverageApiService,
    SearchApiService,
    ExchangeRateApiService,
    CoverageCommodityTypeApiService,
    DocumentApiService,
    LabelGeneratorApiService,
    BolGeneratorApiService,
    LoadTenderGeneratorApiService,
    UserTitleApiService,
    BillDutiesToPartyTypeApiService,
    CustomsBrokerApiService,
    HazmatClassTypeApiService,
    HazmatModeTypeApiService,
    ImportExportTypeApiService,
    TransportTypeApiService,
    InvoiceApiService,
    EdiApiService,
    PageNavigationApiService,
    UserEventNotificationApiService,
    ReasonForExportTypeApiService,
    NonDeliveryHandlingTypeApiService,
    IncotermsTypeApiService,
    InvoiceCsvBatchApiService,
    MarketPlaceStepNavigationApiService,
    B13aFilingOptionApiService,
    ExportComplianceStatementApiService,
    TrackingUpdateCsvBatchApiService,
    PickupApiService,
    GmxRegistrationApiService,
    OrderMarketplaceApiService,
    CreditApplicationApiService,
}
