import styled from 'styled-components'
import {
    Grid as MuiGrid,
    TextField as MuiTextField,
    Typography as MuiTypography,
    InputLabel as MuiInputLabel
} from '@material-ui/core'
import { spacing as MuiSpacing } from '@material-ui/system'


export const TextField = styled(styled(MuiTextField)(MuiSpacing)) <{ isFocus?: boolean, size?: string }>`
    & [class^='MuiInputBase-root'] {
        background: white;
    }
    & label {
        white-space: nowrap;
        margin-top: ${(props): number => props.isFocus ? 0 : -4}px;
        & .[class^='MuiTypography-body1'] {
            line-height: unset;
        }
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
`
export const Typography = styled(styled(MuiTypography)(MuiSpacing)) <{ $fontSize?: number, $fontWeight?: number, $color?: string }>`
    font-size: ${(props): number => props.$fontSize ?? 16}px;
    font-weight: ${(props): number => props.$fontWeight ?? 500};
    color: ${(props): string => props.$color ?? props.theme.palette.primary.main};
    display: inline-block;
`

export const RelativeContainer = styled(styled.div(MuiSpacing))`
    position: relative;
`

export const InputLabel = styled(styled(MuiInputLabel)(MuiSpacing)) <{ $backgroundColor?: string }>`
    position: absolute;
    top: -10px;
    left: 4px;
    background: ${(props): string => props.$backgroundColor ?? 'white'};
    white-space: nowrap;
    z-index: 1;
    & p {
        line-height: unset;
    }
`