import React, { useEffect, useRef, useState } from 'react'
import { TextField, Typography, RelativeContainer, InputLabel } from './TextOutlinedLabelForm.styled'
import { FormControl, InputAdornment, Tooltip } from '@material-ui/core'
import { type Control, Controller, type FieldValues } from 'react-hook-form'
import type { FieldConfig } from '../FormSimple'
import TextWithTooltip from '../../TextWithTooltip/TextWithTooltip'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons-lazr-alias'

export interface InputProps {
    value: string
    layoutConfig?: Record<string, string>
    component?: React.ReactElement
}

export interface InputFormProps {
    name?: string
    control: Control<FieldValues>
    setValue: (name: string, value: any, options?: any) => void
    getValues: (name?: string, options?: any) => void
    trigger: (name: string) => Promise<boolean>
    debouncedHandleOnChange: () => void
    field: FieldConfig
    errors?: any
    error?: any
    helperText?: any
}

const TextOutlinedLabelForm: React.FC<InputFormProps> = ({
    name,
    field,
    control,
    errors,
    error,
    helperText,
    setValue,
    getValues,
    trigger,
    debouncedHandleOnChange,
}) => {

    const { type, values, labelName, spaceForError, disabled, required, size, InputProps, helperText:textHelperText, isFocus:isDefaultFocus, layoutConfig } = field
    const [ isFocus, setIsFocus ] = useState<boolean>(!!field.values);
    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setValue(name ? name : field.name, field.values)
        debouncedHandleOnChange?.()
    }, [field.values])
    return (
        <Controller
            name={name ? name : field.name}
            control={control}
            defaultValue={field.defaultValue || ''}
            render={({ field }) => {
                const errorField = error ? error : errors?.[field.name]
                const helperTextField = textHelperText ? textHelperText : errors?.[field.name]?.message ?? spaceForError ?? ' '
                return (
                    <RelativeContainer>
                        <InputLabel $backgroundColor={layoutConfig?.labelColor}>
                            <TextWithTooltip
                                fontSize={12}
                                fontWeight={600}
                                text={labelName} 
                                tooltip={{icon: faCircleQuestion as IconProp, title: '123488'}} 
                            />
                        </InputLabel>
                        <TextField
                            variant='outlined'
                            isFocus={isFocus}
                            disabled={disabled}
                            size={size}
                            value={field.value > 0 ? field.value : undefined}
                            required={!!required}
                            type={type === 'hiddenText' ? 'hidden' : 'text'}
                            fullWidth
                            error={!!errorField}
                            helperText={helperTextField}
                            onFocus={() => setIsFocus(true)}
                            onBlur={() => setIsFocus(false)}
                            InputProps={(isFocus || values) && InputProps}
                            // InputLabelProps={{
                            //     shrink: !!isDefaultFocus,
                            // }}
                            onChange={(e: any) => {
                                const inputValue = parseInt(e.target.value)
                                const value = isNaN(inputValue) ? -1 : inputValue
                                if (field.onChange) {
                                    field.onChange(value)
                                    setValue(field.name, value, {
                                        shouldValidate: false,
                                    })
                                    debouncedHandleOnChange?.()
                                }

                                // Trigger validation on change
                                trigger(field.name).then(() => {
                                    const isFieldValid = !errorField
                                    if (isFieldValid && field.onChange) {
                                        field.onChange(value)
                                        setValue(field.name, value, {
                                            shouldValidate: false,
                                        })
                                        debouncedHandleOnChange?.()
                                    }
                                })
                            }}
                        />
                    </RelativeContainer>
                )
            }}
        />
    )
}

export default TextOutlinedLabelForm
