import React, { useEffect } from 'react'
import { FormControl, FormHelperText, Grid, ListItem, MenuItem } from '@material-ui/core'
import { type Control, Controller, type FieldValues } from 'react-hook-form'
import type { FieldConfig } from '../FormSimple'
import CarrierLogo from '../../CarrierLogo/CarrierLogo'
import { carrierCodeMapping } from '@/app/ui-new/pages/marketplace/OriginAndDestination/constants' ///TODO: We need to create a a const that shareble to our system
import { Typography, InputLabel, ValueTypography, Select, RelativeContainer, Icon, SecondaryButton } from './OutlinedSelectForm.styled'
import { faCalculator, faCircleQuestion } from '@fortawesome/pro-regular-svg-icons-lazr-alias'
import TextWithTooltip from '../../TextWithTooltip/TextWithTooltip'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export interface InputProps {
    value: string
    layoutConfig?: Record<string, string>
    component?: React.ReactElement
}

export interface InputFormProps {
    name?: string
    control: Control<FieldValues>
    setValue: (name: string, value: any, options?: any) => void
    getValues: (name?: string, options?: any) => void
    trigger: (name: string) => Promise<boolean>
    debouncedHandleOnChange: () => void
    field: FieldConfig
    errors?: any
    error?: any
    helperText?: any
}

const OutlinedSelectForm: React.FC<InputFormProps> = ({
    name,
    field,
    control,
    errors,
    error,
    helperText,
    setValue,
    getValues,
    trigger,
    debouncedHandleOnChange,
}) => {
    const { labelName, required, size, isFocus: isDefaultFocus, layoutConfig } = field

    // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
    useEffect(() => {
        setValue(field.name, field.values ?? field.defaultValue)
        debouncedHandleOnChange?.()
    }, [field.values])
    return (
        <>
            <Controller
                name={field.name}
                control={control}
                defaultValue={field.defaultValue}
                render={({ field: { onChange, value } }) => (
                    <RelativeContainer>
                        <FormControl fullWidth variant="outlined" size={size}>
                            <InputLabel $backgroundColor={layoutConfig?.labelColor}>
                                <TextWithTooltip
                                    fontSize={16}
                                    fontWeight={600}
                                    text={labelName} 
                                    tooltip={{icon: faCircleQuestion as IconProp, title: '123488'}} 
                                />
                            </InputLabel>
                            <Grid container spacing={1}>
                                <Grid item xs={field.select?.extraInfo ? 9 : 12}>
                                    <Select
                                        size={size}
                                        id={`outline-select-${field.name}`}
                                        labelId={`outline-select-${field.name}-label`}
                                        value={field.values ?? (field.defaultValue ?? 'None')}
                                        onChange={(e, newValue: any) => {
                                            onChange(newValue?.props?.value)
                                            setValue(field.name, newValue?.props?.value, { shouldValidate: false })
                                            // Trigger validation on change
                                            trigger(field.name).then(() => {
                                                const isFieldValid = !errors[field.name]
                                                if (isFieldValid && onChange) {
                                                    onChange(newValue?.props?.value)
                                                    setValue(field.name, newValue?.props?.value, {
                                                        shouldValidate: true,
                                                    })
                                                }
                                                debouncedHandleOnChange?.()
                                            })
                                        }}
                                        renderValue={(value) => {
                                            return <ValueTypography>{value !== 'None' ? value : '-'}</ValueTypography>
                                        }}
                                    >
                                        {
                                            field.select?.data?.map((item: string) => {
                                                return (
                                                    <MenuItem value={item}>
                                                        {item}
                                                    </MenuItem>
                                                    )
                                            })
                                        }
                                    </Select>
                                </Grid>
                                {field.select?.extraInfo && <Grid xs={3}>
                                    {field.select.extraInfo}
                                </Grid>}
                            </Grid>
                            {/* {props.helperText ? <FormHelperText>{props.helperText}</FormHelperText> : null} */}
                        </FormControl>
                    </RelativeContainer>
                )}
            />
            {errors[field.name] && (
                <Typography variant='caption' color='error'>
                    {errors[field.name]?.message}
                </Typography>
            )}
        </>
    )
}

export default OutlinedSelectForm
